import React, { useEffect } from "react";
import SingleChoice from "./SingleChoice";
import { observer } from "mobx-react-lite";
import NextStep from "./NextStep";
import SubmitButton from "./SubmitButton";
import { contactStore } from "./ContactStore";

export interface IContactData {
	buttonLabel: string;
}

const Contact = observer((props: IContactData) => {

	useEffect(() => {
		let url = window.location;

		if (!url) return;

		const hash = url.hash;

		if (!hash) return;

		const singleChoices = document.querySelectorAll(".answers a");

		if (singleChoices) {
			singleChoices.forEach((choice) => {
				let href = choice.getAttribute("href");

				if (hash == href) {
					const answerId = choice.id;

					contactStore.selectAnswer(answerId)
				}

			})
		}
	});


	return (
		<>
			<SingleChoice />
			<NextStep
				buttonLabel={props.buttonLabel}
			/>
			<SubmitButton
				buttonLabel={props.buttonLabel}
			/>
		</>
	)
});

export default Contact;