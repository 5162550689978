import "intersection-observer";
import swiper from "./swiper";
// import lazyload from "./dal-lazyload";
import "lazysizes";
import navigation from "./animations/navigation";
import contactFormSettings from "./components/contact-form-settings";
import magicMouseHover from "./animations/mouse-hover-invert";
import splitTextIntoLines from "./animations/split-text-into-lines";
import banner from "./animations/banner";
import fixScrollingSection from "./animations/fix-scrolling-section";
import clientShuffle from "./animations/client-shuffle";
import numberedSlider from "./layoutElements/numbered-slider";
import department from "./layoutElements/department";
import followMouse from "./animations/follow-mouse";
import heroVideo from "./animations/hero-video";
import headerTheming from "./components/header-theming";
import headerThemingFirstSection from "./components/header-theming-first-section";
import menuIcons from "./animations/menu-icons";
import textCollapsible from "./layoutElements/textCollapsible";
import stickyArrowButton from "./pages/stickyArrowButton";
import faviconModes from "./components/favicon-modes";
import pageTransitions from "./animations/page-transitions"
import cookieBanner from "./components/cookie-banner";
import projectPage from "./animations/project-page";
import reactContact from "../react/index";
import footerHover from "./components/footer-hover";
import languageSwitcher from "./components/language-switcher";
import caseStudyVideo from "./layoutElements/case-study-video";
import projectDetailsMenu from "./pages/project-details-menu";
import hideFooter from "./components/hide-footer";
import textSection from "./layoutElements/text-section";
import contactDataDisplay from "./components/contact-data-display";
import mediaCaseStudies from "./animations/media-case-studies";
import projectDetailPage from "./animations/project-details-page";
import bestOfHeightMobile from "./components/best-of-height-mobile";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	initStaticFunctions();

	initSectionBasedFunctions();

	pageTransitions.init(headerThemingFirstSection, fixScrollingSection, initSectionBasedFunctions);

	function initStaticFunctions() {
		const headerMenu = document.querySelector(".header-menu");
		if (headerMenu) {
			navigation.init(headerMenu, headerThemingFirstSection);
		}

		let menuItems = document.querySelectorAll(".menu-item");
		if (menuItems.length) {
			menuItems.forEach((item) => {
				menuIcons.init(item);
			})
		}

		headerThemingFirstSection.init();

		let fixedSections = document.querySelectorAll(".best-ofs-desktop");
		if (fixedSections.length) {
			fixedSections.forEach((section) => {
				fixScrollingSection.init(section);
			})
		}

		faviconModes.init();

		const footer = document.querySelector("footer");
		if (footer) {
			footerHover.init(footer);
		}
	}

	function initSectionBasedFunctions() {
		numberedSlider.init();

		reactContact.init();

		contactFormSettings.init();

		const pageContainer = document.querySelector(".page-content-wrapper");
		if (pageContainer) {
			languageSwitcher.init(pageContainer);
		}

		const sections = document.querySelectorAll("section");
		if (sections.length) {
			headerTheming.init(sections);
		}

		const projectDetailPages = document.querySelectorAll(".project-details-page");
		const body = document.querySelector("body");

		if (projectDetailPages.length) {
			projectDetailPages.forEach((detailPage) => {
				projectDetailsMenu.init(detailPage);
				projectDetailPage.init(detailPage);

				if (!body) return;
				body.classList.add("no-callout");
			})
		} else {
			if (!body) return;

			if(body.classList.contains("no-callout")) {
				body.classList.remove("no-callout");
			}
		}


		const caseStudySections = document.querySelectorAll(".media-case-studies");
		if (caseStudySections.length) {
			caseStudySections.forEach(function (section) {
				caseStudyVideo.init(section);
				mediaCaseStudies.init(section);
			})
		}

		hideFooter.init();

		// lazyload.init();

		//Init all swiper if there are some
		let allSwiper = document.querySelectorAll(".swiper-container");
		if (allSwiper.length) {
			allSwiper.forEach(function (swiperEle) {
				//Set swiper element and swiper options from data-attribute
				swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
			})
		}

		textCollapsible.init();

		department.init();

		stickyArrowButton.init();

		cookieBanner.init();

		splitTextIntoLines.init();

		let bannerSections = document.querySelectorAll(".banner");
		if (bannerSections.length) {
			bannerSections.forEach((section) => {
				banner.init(section);
			})
		}

		let clientSuffleSections = document.querySelectorAll(".client-shuffle");
		if (clientSuffleSections.length) {
			clientSuffleSections.forEach((section) => {
				clientShuffle.init(section);
			})
		}

		let projectPageSection = document.querySelector(".project-page");
		if (projectPageSection) {
			projectPage.init(projectPageSection);
		}

		let textSections = document.querySelectorAll(".text-section");
		if (textSections.length) {
			textSections.forEach((section) => {
				textSection.init(section);
			})
		}

		let heroVideoSections = document.querySelectorAll(".hero-video");
		if (heroVideoSections.length) {
			heroVideoSections.forEach((section) => {
				heroVideo.init(section);
				followMouse.init(section);
			})
		}

		const bestOfMobile = document.querySelectorAll(".best-ofs-mobile");
		if(bestOfMobile.length) {
			bestOfMobile.forEach((bestOfm) => {
				bestOfHeightMobile.init(bestOfm);
			})
		}

		const contactDatas = document.querySelectorAll(".contact-data");
		if (contactDatas.length) {
			contactDatas.forEach((data) => {
				contactDataDisplay.init(data);
			})
		}

		magicMouseHover.init();
	}

})();