import ScrollMagic from "scrollmagic";

let controller;
let sceneEnter;
let sceneLeave;

export default {
	init(sections) {
		if (controller) {
			controller = controller.destroy(true);
		}

		if (sceneEnter) {
			sceneEnter = sceneEnter.destroy(true);
		}

		if (sceneLeave) {
			sceneLeave = sceneLeave.destroy(true);
		}

		const header = document.querySelector("header");
		const body = document.querySelector("body");

		if (!header || !sections || !body) {
			return;
		}

		const headerClasslist = header.classList;
		const bodyClasslist = body.classList;

		if (sections.length) {

			controller = new ScrollMagic.Controller();

			sections.forEach((section) => {

				// Scroll Down
				sceneEnter = new ScrollMagic.Scene({ triggerElement: section, triggerHook: 0 })
					.addTo(controller)

				sceneEnter.on("enter", function (e) {
					let scrollDirection = e.target.controller().info("scrollDirection");

					if (scrollDirection == "FORWARD") {
						changeHeaderColor(header, headerClasslist, bodyClasslist, section)
					}
				})

				// Scroll Up
				sceneLeave = new ScrollMagic.Scene({ triggerElement: section.nextElementSibling, triggerHook: 0 })
					.addTo(controller)


				sceneLeave.on("leave", function (e) {
					let scrollDirection = e.target.controller().info("scrollDirection");

					if (scrollDirection == "REVERSE" && section.nextElementSibling) {
						changeHeaderColor(header, headerClasslist, bodyClasslist, section)
					}
				})
			})
		}

		function changeHeaderColor(header, headerClasslist, bodyClasslist, section) {
			const sectionClasslist = section.classList;

			// remove theming class of header if exists
			headerClasslist.forEach((headerClass) => {
				if (headerClass.match(/^theming-/)) {
					header.classList.remove(headerClass);
				}
			})

			bodyClasslist.forEach((bodyClass) => {
				if (bodyClass.match(/^body-theming-/)) {
					body.classList.remove(bodyClass);
				}
			})

			// add new theming class to header depening on current section theming
			sectionClasslist.forEach((sectionClass) => {
				if (sectionClass.match(/^theming-/)) {
					header.classList.add(sectionClass);
					body.classList.add("body-" + sectionClass);
				}
			})
		}
	},
}

