import { supportsPassiveEvents } from "detect-it";

export default {

	init(data) {
		const iconContainer = data.querySelector(".icon-container");
		const verticalContainer = data.querySelector(".vertical-container");

		if (!iconContainer || !verticalContainer) return;

		showCorrectContactData();

		window.addEventListener("resize", () => {
			showCorrectContactData();
		}, supportsPassiveEvents ? { passive: true } : false);

		// Show full text on large devices, show icons on small screen heights
		function showCorrectContactData() {
			const headerHeight = 80;
			const screenHeight = window.innerHeight;
			const contentHeight = headerHeight + verticalContainer.offsetWidth + 40;

			if (!contentHeight) return;

			if (contentHeight > screenHeight) {
				verticalContainer.style.display = "none";
				iconContainer.classList.remove("hidden");
			} else {
				if (!iconContainer.classList.contains("hidden")) {
					iconContainer.classList.add("hidden");
					verticalContainer.removeAttribute("style");
				}
			}

		}

	},
}
