import KeenSlider from "keen-slider";

export default {

	init(section) {

		if (!section) return;

		const animation = { duration: 22000, easing: (t) => t };
		const index = 8;

		new KeenSlider("#banner-slider", {
			loop: true,
			renderMode: "performance",
			drag: false,
			slides: { perView: "auto" },
			created(s) {
				s.moveToIdx(index, true, animation);
			},
			updated(s) {
				s.moveToIdx(s.track.details.abs + index, true, animation);
			},
			animationEnded(s) {
				s.moveToIdx(s.track.details.abs + index, true, animation);
			},
		});
	},
}


