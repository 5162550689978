export default {

	init(bestOfContainer) {

		if(!bestOfContainer) return;

		adaptCardHeight();

		// get largest card content and set height to each card
		function adaptCardHeight() {
			const headlines = bestOfContainer.querySelectorAll(".swiper-slide h3");

			if (headlines.length) {
				let headlineHeight = 0;
		
				headlines.forEach((headline) => {
					const cardTextHeight = headline.offsetHeight;
		
					if (cardTextHeight > headlineHeight) {
						headlineHeight = cardTextHeight;
					}
				});
		
				headlines.forEach((headline) => {
					headline.style.height = headlineHeight + "px";
				});
			}
		}
	},
}
