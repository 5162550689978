import { supportsPassiveEvents } from "detect-it";

export default {

	init(section) {

		if (!section) return;

		const video = section.querySelector(".case-study-video");

		if (!video) return;

		if (video.classList.contains("no-controls")) {
			setTimeout(createVideoWithoutControls(video), 300);
		} else {
			setTimeout(createVideoWithControls(video), 300);
			playPauseVideo(video);
			changeVolume(video);
		}

		/**
		 * create video element with source and video poster
		 */
		function createVideoWithControls(video) {
			video.setAttribute("src", video.dataset.srcdesktop);
			video.setAttribute("poster", video.dataset.poster);
			video.setAttribute("preload", "auto");
		}

		/**
		 * create video element with source and video poster
		 */
		function createVideoWithoutControls(video) {
			video.setAttribute("src", video.dataset.srcdesktop);
			video.setAttribute("poster", video.dataset.poster);
			video.setAttribute("preload", "auto");
			video.setAttribute("loop", "loop");

			const currentVideo = section.querySelector(".case-study-video.no-controls");

			if (!currentVideo) return;

			let playPromise = currentVideo.play();

			if (playPromise !== undefined) {
				playPromise.then(() => {
					currentVideo.play();
				})
					.catch(() => {
					});
			}
		}

		/**
		 * handle visibility of correct icon depending on volume
		 * mute and unmute video
		 */
		function changeVolume(video) {
			const volumeButton = section.querySelector(".video-volume");

			if (!volumeButton) return;

			volumeButton.addEventListener("click", (e) => {
				const mute = volumeButton.querySelector(".mute");
				const volume = volumeButton.querySelector(".volume");

				e.stopPropagation();

				if (!mute || !volume) return;

				video.muted = !video.muted;
				mute.classList.toggle("hidden");
				volume.classList.toggle("hidden");
			}, supportsPassiveEvents ? { passive: true } : false)
		}

		/**
		 * handle visibility of correct icon depending on play state
		 * play and pause video
		 */
		function playPauseVideo(video) {
			const videoPlayButton = section.querySelector(".video-play .play");
			const videoPauseButton = section.querySelector(".video-play .pause");
			const videoPlayButtonBig = section.querySelector(".video-play .play-big");

			if (!videoPlayButton || !videoPauseButton) return;

			video.addEventListener("click", (e) => {
				e.stopPropagation();

				if (video.paused) {
					video.play();
					videoPlayButton.classList.toggle("hidden");
					videoPauseButton.classList.toggle("hidden");
					if (videoPlayButtonBig) {
						videoPlayButtonBig.classList.toggle("hidden");
					}
				} else {
					video.pause();
					videoPlayButton.classList.toggle("hidden");
					videoPauseButton.classList.toggle("hidden");
					if (videoPlayButtonBig) {
						videoPlayButtonBig.classList.toggle("hidden");
					}
				}

			}, supportsPassiveEvents ? { passive: true } : false)

			videoPlayButton.addEventListener("click", (e) => {
				e.stopPropagation();

				video.play();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				if (videoPlayButtonBig) {
					videoPlayButtonBig.classList.toggle("hidden");
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			videoPauseButton.addEventListener("click", (e) => {
				e.stopPropagation();

				video.pause();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				if (videoPlayButtonBig) {
					videoPlayButtonBig.classList.toggle("hidden");
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			videoPlayButtonBig.addEventListener("click", (e) => {
				e.stopPropagation();

				video.play();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				videoPlayButtonBig.classList.toggle("hidden");
			}, supportsPassiveEvents ? { passive: true } : false)
		}
	},
}
