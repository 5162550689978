import anime from "animejs";

export default {
	init(page) {
		const timelineHeadline = anime.timeline();
		const timelineClaim = anime.timeline();
		const timelineDescription = anime.timeline();
		const timelineData = anime.timeline();
		const headline = page.querySelector("h1");
		const claim = page.querySelector(".project-claim");
		const description = page.querySelector(".description");
		const data = page.querySelectorAll(".data-wrapper div");

		if (headline) {
			timelineHeadline
				.add({
					targets: headline,
					translateY: ["3rem", 0],
					easing: "easeOutQuint",
					duration: 800,
				}, 200)
		}

		if (claim) {
			timelineClaim
				.add({
					targets: claim,
					translateY: ["5rem", 0],
					opacity: [0, 1],
					easing: "easeOutQuint",
					duration: 800,
				}, 300)
		}

		if (description) {
			timelineDescription
				.add({
					targets: description,
					translateY: ["3rem", 0],
					opacity: [0, 1],
					easing: "easeOutQuint",
					duration: 800,
				}, 500)
		}

		if (data) {
			timelineData
				.add({
					targets: data,
					translateX: ["5rem", 0],
					opacity: [0, 1],
					easing: "easeOutQuint",
					duration: 800,
					delay: anime.stagger(100),
				}, 600)
		}

	},
}
