import anime from "animejs";
import { supportsPassiveEvents } from "detect-it";

export default {
    init(section) {
        let url = window.location;
        const windowWidth = window.innerWidth;
        const contentDiv = document.querySelector(".content");
        const body = document.querySelector("body");

        if (contentDiv) {
            contentDiv.className += " overflow-hidden bg-sand h-screen";
        }

        const projectCategories = section.querySelector(".project-categories");
        const contentData = section.querySelector(".data-content");
        const clients = section.querySelector(".clients");

        if (!projectCategories || !contentData || !clients || !url) {
            return;
        }

        let params = new URLSearchParams(url.search);
        const links = projectCategories.querySelectorAll(".project-category-link");
        const projectCategoryBackLinks = contentData.querySelectorAll(".project-category-back-link");
        const activeLink = projectCategories.querySelector(".active");

        if (!projectCategoryBackLinks.length || !links.length || !activeLink) return;

        if (!params.get("category") || windowWidth > 640) {
            showClientsDependingOnCategory(activeLink);
        } else {
            const currentCategory = params.get("category");
            const projectCatLinks = section.querySelectorAll(".project-category-link");

            if (!projectCatLinks) return;

            projectCatLinks.forEach((link) => {
                link.classList.remove("active");
                const categoryId = link.getAttribute("category-id");

                if (currentCategory === categoryId) {
                    link.classList.add("active");
                    showClientsDependingOnCategory(link);
                }
            })

        }

        links.forEach(link => {
            link.addEventListener("click", () => {
                showClientsDependingOnCategory(link);
            }, supportsPassiveEvents ? { passive: true } : false)
        })

        /**
         * Get related clients based on project category
         *
         * @param {string} linkId of project category
         */
        function getRelatedClients(linkId) {
            const clientsArray = [];

            const caseStudies = contentData.querySelectorAll(".case-study");
            if (!caseStudies.length) {
                return clientsArray
            }

            caseStudies.forEach(caseStudy => {
                const categoryIds = caseStudy.getAttribute("category-ids");
                if (categoryIds) {
                    const categoryIdsArray = categoryIds.split(",");

                    if (linkId != "all") {
                        if (!categoryIdsArray.includes(linkId)) {
                            caseStudy.classList.add("hidden");
                        } else {
                            caseStudy.classList.remove("hidden");
                        }
                    } else {
                        caseStudy.classList.remove("hidden");
                    }

                    clientsArray.push(caseStudy);
                }
            });

            return clientsArray
        }

        /**
         * Animate incoming clients after link click
         *
         * @param {array} clientsArray of clients
         */
        function animateIncomingClients(clientsArray) {
            const texts = [];
            clientsArray.forEach(elem => {
                texts.push(elem.querySelector(".client-headline"));
                elem.classList.add("pointer-events-none");
            });

            const timeline = anime.timeline();
            timeline
                .add({
                    targets: texts,
                    keyframes: [
                        { "-webkit-clip-path": "inset(100% 0 0 0)", clipPath: "inset(100% 0 0 0)" },
                        { "-webkit-clip-path": "inset(0% 0 0 0)", clipPath: "inset(0% 0 0 0)" },
                    ],
                    duration: 700,
                    easing: "easeOutQuint",
                    delay: function (el, i) {
                        if (i == 0) {
                            return 0;
                        } else {
                            let random = anime.random(0, 500);
                            return random;
                        }
                    },
                    complete: () => {
                        clientsArray.forEach(elem => {
                            elem.classList.remove("pointer-events-none");
                        });
                    },
                });
        }

        /**
         * Animate outcoming clients after link click
         *
         * @param {array} clientsArray
         * @param {string} clickedLinkId of project category
         */
        function animateOutcomingClients(clientsArray, clickedLinkId) {
            const texts = [];
            clientsArray.forEach((client) => {
                texts.push(client.querySelector(".client-headline"));
                client.classList.add("pointer-events-none");
            })

            const timeline = anime.timeline();

            timeline
                .add({
                    targets: texts,
                    keyframes: [
                        { "-webkit-clip-path": "inset(0% 0 0 0)", clipPath: "inset(0% 0 0 0)" },
                        { "-webkit-clip-path": "inset(100% 0 0 0)", clipPath: "inset(100% 0 0 0)" },
                    ],
                    duration: 700,
                    easing: "easeOutQuint",
                    complete: () => {
                        clientsArray.forEach(elem => {
                            elem.classList.remove("pointer-events-none");
                        });
                    },
                })

            timeline.finished.then(function () {
                const incomingClientsArray = getRelatedClients(clickedLinkId);
                if (incomingClientsArray) {
                    animateIncomingClients(incomingClientsArray);
                }
            })
        }

        /**
         * add selected category as url param
         */
        function updateUrlParams(activeLink) {
            params.delete("category"); // delete category param 
            const categoryId = activeLink.getAttribute("category-id");

            params.append("category", categoryId); // set category as url param
            history.replaceState(null, null, "?" + params.toString()); // push new param
        }

        function showClientsDependingOnCategory(link) {
            const activeLink = projectCategories.querySelector(".active");
            const linkSpan = link.querySelector("span");
            const caseStudies = clients.querySelectorAll(".case-study");
            let currentClientsArray;
            const clickedLinkId = link.id;

            if (!linkSpan || !caseStudies) return;

            if (activeLink) {
                const activeLinkId = activeLink.id;

                if (!activeLink) return;

                currentClientsArray = getRelatedClients(activeLinkId);
            } else {
                currentClientsArray = getRelatedClients(clickedLinkId);
            }

            if (!currentClientsArray) return;


            //for mobile it will be animated only the Incoming data
            if (windowWidth <= 640) {
                clients.classList.add("is-visible");
                body.classList.add("overflow-hidden");
                const incomingClientsArray = getRelatedClients(clickedLinkId);
                if (incomingClientsArray) {
                    animateIncomingClients(incomingClientsArray);
                }
            } else {
                animateOutcomingClients(currentClientsArray, clickedLinkId);
            }

            link.classList.add("active");
            updateUrlParams(link);

            //to remove last active link
            links.forEach(btn => {
                if (btn.id != clickedLinkId) {
                    btn.classList.remove("active");
                }
            });

            const categoryId = link.getAttribute("category-id");

            // set current category to client links 
            caseStudies.forEach((caseStudy) => {
                const caseStudyLink = caseStudy.querySelector(".case-study-link");

                if (!caseStudyLink) return;

                let href = caseStudyLink.getAttribute("href");
                let currentHref = href.split("?");
                caseStudyLink.setAttribute("href", currentHref[0] + "?category=" + categoryId)
            })

            //for mobile styling during the click link
            if (windowWidth <= 640) {
                const linkcategoryId = link.getAttribute("category-id");
                contentData.classList.add("active-content");
                projectCategories.classList.add("project-categories-left");

                projectCategoryBackLinks.forEach(backLink => {
                    const backLinkcategoryId = backLink.getAttribute("category-id");
                    if (backLinkcategoryId) {
                        if (linkcategoryId != backLinkcategoryId) {
                            backLink.classList.add("hidden");
                        }
                    }
                });
            }

            //for mobile styling during the click back link
            if (windowWidth <= 640) {
                const linksBack = contentData.querySelectorAll(".project-category-back-links");
                if (linksBack.length) {
                    linksBack.forEach(linkBack => {
                        linkBack.addEventListener("click", () => {
                            //to show only the relevant link
                            projectCategoryBackLinks.forEach(link => {
                                link.classList.remove("hidden");
                            });

                            contentData.classList.remove("active-content");
                            projectCategories.classList.remove("project-categories-left");
                            clients.classList.remove("is-visible");
                            body.classList.remove("overflow-hidden");

                            const clientHeadlines = clients.querySelectorAll(".client-headline");

                            if (!clientHeadlines) return;

                            clientHeadlines.forEach((headline) => {
                                headline.removeAttribute("style");
                            })
                        }, supportsPassiveEvents ? { passive: true } : false);
                    });
                }
            }
        }

    },
}

