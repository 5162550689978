import magicMouse from "magicmouse.js";

export default {

	init() {

		const magicPointer = document.querySelector("#magicPointer");

		if (magicPointer) {
			magicPointer.remove();

			let options = {
				"cursorOuter": "disable",
				"hoverEffect": "pointer-overlay",
				"hoverItemMove": false,
				"defaultCursor": false,
				"outerWidth": 30,
				"outerHeight": 30,
			};

			magicMouse(options);

			checkDragging();

			// checkScrolling();


		} else {
			let options = {
				"cursorOuter": "disable",
				"hoverEffect": "pointer-overlay",
				"hoverItemMove": false,
				"defaultCursor": false,
				"outerWidth": 30,
				"outerHeight": 30,
			};

			magicMouse(options);

			checkDragging();

			// checkScrolling();
		}

		// function checkScrolling() {
		// 	window.addEventListener("scroll", () => {
		// 		const magicPointer = document.querySelector("#magicPointer");
		// 		if (magicPointer || magicPointer.classList.contains("pointer-overlay")) {
		// 			magicPointer.classList.remove("pointer-overlay");
		// 		}
		// 	})
		// }

		/**
		 * Check if user drags swiper
		 * if true, remove magic hover effect and show grab cursor
		 */
		function checkDragging() {
			const swiperContainers = document.querySelectorAll(".swiper-container");

			if (!swiperContainers) return;


			swiperContainers.forEach((container) => {
				const swiper = container.swiper;
				const slideLinks = container.querySelectorAll(".swiper-slide a");
				let mouseEffectExists = false;

				if (!slideLinks) return;

				slideLinks.forEach((slideLink) => {
					if (slideLink.classList.contains("magic-hover")) {
						mouseEffectExists = true;
					}
				})

				swiper.on("touchMove", function () {
					if (mouseEffectExists) {
						const magicPointer = document.querySelector("#magicPointer");
						if (magicPointer && magicPointer.classList.contains("pointer-overlay")) {
							magicPointer.classList.remove("pointer-overlay");
						}

						slideLinks.forEach((slideLink) => {
							slideLink.classList.remove("magic-hover");
							slideLink.classList.add("grab-cursor");
						})
					}


				});

				swiper.on("touchEnd", function () {
					if (mouseEffectExists) {
						const magicPointer = document.querySelector("#magicPointer");
						if (magicPointer && !magicPointer.classList.contains("pointer-overlay")) {
							magicPointer.classList.add("pointer-overlay");
						}

						slideLinks.forEach((slideLink) => {
							slideLink.classList.add("magic-hover");
							slideLink.classList.remove("grab-cursor");
						})
					}

				});
			})
		}
	},
}
