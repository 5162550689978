import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import anime from "animejs";
import utils from "../utils";
import ScrollMagic from "scrollmagic";
import { supportsPassiveEvents } from "detect-it";
import lottie from "lottie-web";

export default {
	init(section) {
		const lg = utils.getMQValue("lg");
		const controller = new ScrollMagic.Controller();

		if (!section) return;

		// create Videos a bit after initial page load
		setTimeout(createVideoHero(section), 300);
		setTimeout(createVideoHeroMobile(section), 300);
		setTimeout(createVideoPreview(section), 300);

		const followMouseContainer = section.querySelector(".follow-mouse-container");
		const previewContainer = section.querySelector(".preview-video-container");
		const videoOverlay = section.querySelector(".video-overlay");
		const iconsContainer = section.querySelector(".icons-container");
		const previewVideo = section.querySelector(".preview-video");
		const heroVideo = section.querySelector("#hero-video");
		let mobileAnimationPlayed = false;
		let desktopAnimationPlayed = false;

		if (!videoOverlay || !previewContainer || !iconsContainer || !previewVideo || !heroVideo) return;

		const playSpan = previewContainer.querySelector(".play-reel");

		// Call home animation on page load
		animationOnPageLoad(iconsContainer, followMouseContainer);


		// Call function depending on screen size
		if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
			changeVolume(heroVideo);
			playPauseVideo(heroVideo);
			useDesktopAnimation(section, followMouseContainer, previewContainer, videoOverlay, iconsContainer, previewVideo, playSpan, heroVideo);
		} else {
			showMobileVideo();
			useMobileAnimation(section, videoOverlay, iconsContainer, previewVideo, heroVideo, playSpan);

			const heroVideoMobile = section.querySelector("#hero-video-mobile");

			if (!heroVideoMobile) return;

			// show video poster mobile if video is paused either by the user or programmatically
			heroVideoMobile.addEventListener("pause", () => {
				const videoPosterMobile = section.querySelector(".video-mobile-poster");
				if (videoPosterMobile) {
					videoPosterMobile.style.removeProperty("opacity");
					videoPosterMobile.classList.remove("pointer-events-none");
				}
			});
		}

		// show video poster if video is paused either by the user or programmatically
        heroVideo.addEventListener("pause", () => {
			const videoPlayButton = section.querySelector(".video-play .play");

			if (!videoPlayButton) return;

			if (videoPlayButton.classList.contains("hidden")) {
				videoPlayButton.classList.toggle("hidden");
				videoPlayButton.classList.add("paused-programm")
			}
        });


		// Center video bubble if user is scrolling
		const scene = new ScrollMagic.Scene({ triggerElement: section, triggerHook: 0 })
			.addTo(controller)

		scene.on("update", function () {
			previewContainer.style.removeProperty("transform");
		})


		/**
		 * Animate section on large devices
		 */
		function useDesktopAnimation(section, followMouseContainer, previewContainer, videoOverlay, iconsContainer, previewVideo, playSpan, heroVideo) {
			const closeButton = section.querySelector(".close-button");
			const followMouseContainerWidth = followMouseContainer.clientWidth;
			const followMouseContainerHeight = followMouseContainer.clientHeight;
			const previewContainerWidth = previewContainer.clientWidth;
			const previewContainerHeight = previewContainer.clientHeight;
			const previewVideoWidth = previewVideo.clientWidth;
			const previewVideoHeight = previewVideo.clientHeight;
			const sectionWidth = section.clientWidth;
			const sectionHeight = section.clientHeight;
			const videoElem = getVideoHeroElement();
			const videoPlayContainers = section.querySelectorAll(".video-play");
			const videoPlayButton = section.querySelector(".video-play .play");

			previewVideo.addEventListener("click", function () {
				const mainTimeline = anime.timeline();
				const additionalTimeline = anime.timeline();
				desktopAnimationPlayed = true;
				followMouseContainer.classList.add("not-following");

				if (!videoPlayButton) return;

				if (videoPlayButton.classList.contains("paused-programm")) {
					videoPlayButton.classList.remove("hidden")
				}

				if (!videoPlayButton.classList.contains("hidden")) {
					videoPlayButton.classList.toggle("hidden")
				}

				if (desktopAnimationPlayed || !mobileAnimationPlayed) {
					additionalTimeline
						.add({
							targets: iconsContainer,
							translateY: ["0%", "-300%"],
							easing: "easeOutQuint",
							duration: 1000,
						}, 600)

					mainTimeline
						.add({
							targets: playSpan,
							opacity: [1, 0],
							easing: "easeOutQuint",
							duration: 400,
						})
						.add({
							targets: section,
							height: [section.clientHeight, window.innerHeight],
							easing: "easeOutQuint",
							duration: 1000,
						}, 600)
						.add({
							targets: followMouseContainer,
							width: [followMouseContainerWidth, "100%"],
							height: [followMouseContainerHeight, "100%"],
							easing: "easeOutQuint",
							duration: 10,
						}, 600)
						.add({
							targets: previewContainer,
							width: [previewContainerWidth, "100%"],
							height: [previewContainerHeight, "100%"],
							easing: "easeOutQuint",
							duration: 10,
						}, 850)
						.add({
							targets: previewVideo,
							keyframes: [
								{ clipPath: "circle(50% at 50% 50%)" },
								{ clipPath: "circle(100% at 50% 50%)" },
							],
							width: heroVideo.clientWidth + "px",
							height: heroVideo.clientHeight + "px",
							easing: "easeInOutQuint",
							duration: 600,
						}, 950)
						.add({
							targets: videoOverlay,
							opacity: [0, 1],
							easing: "easeOutQuint",
							duration: 400,
							complete: () => {
								if (videoPlayContainers) {
									videoPlayContainers.forEach(container => {
										container.classList.add("pointer-events-auto");
									});
								}
								videoOverlay.classList.remove("pointer-events-none");
								disableBodyScroll(videoOverlay);
								previewVideo.pause();
								videoElem.play();
							},
						}, "-=100")

					// close video overlay on large devices
					closeButton.addEventListener("click", function () {
						const timeline = anime.timeline();
						iconsContainer.style.removeProperty("transform");
						previewVideo.play();

						timeline
							.add({
								targets: section,
								width: ["100%", sectionWidth + "px"],
								height: ["100%", sectionHeight + "px"],
								easing: "easeOutQuint",
								duration: 100,
							})
							.add({
								targets: followMouseContainer,
								width: ["100%", followMouseContainerWidth + "px"],
								height: ["100%", followMouseContainerHeight + "px"],
								easing: "easeOutQuint",
								duration: 100,
							})
							.add({
								targets: previewContainer,
								width: ["100%", previewContainerWidth + "px"],
								height: ["100%", previewContainerHeight + "px"],
								easing: "easeOutQuint",
								duration: 100,
							})
							.add({
								targets: videoOverlay,
								opacity: [1, 0],
								easing: "easeOutQuint",
								duration: 400,
								complete: () => {
									videoOverlay.classList.add("pointer-events-none");
									enableBodyScroll(videoOverlay);
									videoElem.pause();
									videoElem.currentTime = 0;
								},
							})
							.add({
								targets: previewVideo,
								width: [previewVideo.clientWidth, previewVideoWidth + "px"],
								height: [previewVideo.clientHeight, previewVideoHeight + "px"],
								keyframes: [
									{ clipPath: "circle(100% at 50% 50%)" },
									{ clipPath: "circle(50% at 50% 50%)" },
								],
								easing: "easeOutQuint",
								duration: 200,
							}, "-=300")
							.add({
								targets: iconsContainer,
								opacity: [0, 1],
								easing: "easeOutQuint",
								duration: 400,
							})
							.add({
								targets: playSpan,
								opacity: [0, 1],
								easing: "easeOutQuint",
								duration: 400,
								complete: () => {
									followMouseContainer.classList.remove("not-following");
									desktopAnimationPlayed = false;
								},
							})
					}, supportsPassiveEvents ? { passive: true } : false)

				}
			}, supportsPassiveEvents ? { passive: true } : false)

		}

		/**
		 * Animate section on small devices
		 */
		function useMobileAnimation(section, videoOverlay, iconsContainer, previewVideo, heroVideo, playSpan) {
			const closeButtonMobile = section.querySelector(".close-button-mobile");
			const videoElem = getVideoHeroElementMobile();

			previewVideo.addEventListener("click", function () {
				const mainTimelineMobile = anime.timeline();
				const additionalTimelineMobile = anime.timeline();
				mobileAnimationPlayed = true;

				if (mobileAnimationPlayed || !desktopAnimationPlayed) {
					additionalTimelineMobile
						.add({
							targets: iconsContainer,
							translateY: ["0%", "-600%"],
							easing: "easeOutQuint",
							duration: 1000,
						}, 200)

					mainTimelineMobile
						.add({
							targets: playSpan,
							opacity: [1, 0],
							easing: "easeOutQuint",
							duration: 400,
						})
						.add({
							targets: videoOverlay,
							opacity: [0, 1],
							easing: "easeInOutQuint",
							duration: 800,
							complete: () => {
								videoOverlay.classList.remove("pointer-events-none");
								previewVideo.classList.add("hidden");
								disableBodyScroll(videoOverlay);
							},
						}, 400)

					// close video overlay on small devices
					closeButtonMobile.addEventListener("click", function () {
						const timelineMobile = anime.timeline();
						const videoPosterMobile = section.querySelector(".video-mobile-poster");
						iconsContainer.style.removeProperty("transform");
						previewVideo.classList.remove("hidden");

						timelineMobile
							.add({
								targets: videoOverlay,
								opacity: [1, 0],
								easing: "easeInOutQuint",
								duration: 600,
								complete: () => {
									videoOverlay.classList.add("pointer-events-none");
									videoElem.pause();
									videoElem.currentTime = 0;
									enableBodyScroll(videoOverlay);
								},
							})
							.add({
								targets: iconsContainer,
								opacity: [0, 1],
								easing: "easeOutQuint",
								duration: 400,
							})
							.add({
								targets: playSpan,
								opacity: [0, 1],
								easing: "easeOutQuint",
								duration: 400,
								complete: () => {
									mobileAnimationPlayed = false;

									if (videoPosterMobile) {
										videoPosterMobile.style.removeProperty("opacity");
										videoPosterMobile.classList.remove("pointer-events-none");
									}
								},
							})
					}, supportsPassiveEvents ? { passive: true } : false)
				}
			}, supportsPassiveEvents ? { passive: true } : false)
		}

		/**
		 * Create video hero element for large devices
		 */
		function createVideoHero(section) {
			const newVideoElement = section.querySelector("#hero-video");
			if (newVideoElement !== null) {

				if (newVideoElement.dataset.srcdesktop !== newVideoElement.getAttribute("src")) {
					newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
					newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
					newVideoElement.setAttribute("preload", "auto");
				}

			}
		}

		/**
		 * Create video hero element for small devices
		 */
		function createVideoHeroMobile(section) {
			const newVideoElement = section.querySelector("#hero-video-mobile");
			if (newVideoElement !== null) {

				if (newVideoElement.dataset.srcdesktop !== newVideoElement.getAttribute("src")) {
					newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
					newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
					newVideoElement.setAttribute("preload", "auto");
				}

			}
		}

		/**
		 * Show and start video and remove video poster
		 */
		function showMobileVideo() {
			const videoMobile = getVideoHeroElementMobile();
			const videoPosterMobile = section.querySelector(".video-mobile-poster");
			const timelineMobile = anime.timeline();

			if (!videoMobile || !videoPosterMobile) return;

			videoPosterMobile.addEventListener("click", function () {
				timelineMobile
					.add({
						targets: videoPosterMobile,
						opacity: [1, 0],
						easing: "easeOutQuint",
						duration: 50,
						complete: () => {
							videoMobile.play();
							videoPosterMobile.classList.add("pointer-events-none");
						},
					})
			}, supportsPassiveEvents ? { passive: true } : false)

		}

		/**
		 * Create video preview element
		 */
		function createVideoPreview(section) {
			const newVideoElement = section.querySelector("#preview-video");
			if (newVideoElement !== null) {

				if (newVideoElement.dataset.srcdesktop !== newVideoElement.getAttribute("src")) {
					newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
					newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
				}

				newVideoElement.setAttribute("autoplay", "autoplay");
				newVideoElement.setAttribute("loop", "loop");
				newVideoElement.setAttribute("preload", "auto");
				newVideoElement.setAttribute("muted", "muted");

				const previewVideo = document.getElementById("preview-video");
				let playPromise = previewVideo.play();

				if (playPromise !== undefined) {
					playPromise.then(() => {
						previewVideo.play();
					})
						.catch(() => {
						});
				}
			}
		}

		/**
		 * Returns video hero div
		 */
		function getVideoHeroElement() {
			const heroVideo = document.getElementById("hero-video");
			return heroVideo
		}

		/**
		 * Returns video hero div
		 */
		function getVideoHeroElementMobile() {
			const heroVideo = document.getElementById("hero-video-mobile");
			return heroVideo
		}

		/**
		 * toggle volume/mute button by clicking on volume button
		 */
		function changeVolume(video) {
			const volumeButton = section.querySelector(".video-volume");

			if (!volumeButton) return;

			volumeButton.addEventListener("click", (e) => {
				const mute = volumeButton.querySelector(".mute");
				const volume = volumeButton.querySelector(".volume");

				e.stopPropagation();

				if (!mute || !volume) return;

				video.muted = !video.muted;
				mute.classList.toggle("hidden");
				volume.classList.toggle("hidden");
			}, supportsPassiveEvents ? { passive: true } : false)
		}

		/**
		 * stop video by clicking on video, play button appears
		 */
		function playPauseVideo(video) {
			const videoPlayButton = section.querySelector(".video-play .play");
			const videoPauseButton = section.querySelector(".video-play .pause");
			const videoPlayButtonBig = section.querySelector(".video-play .play-big");

			if (!videoPlayButton || !videoPauseButton) return;

			video.addEventListener("click", (e) => {
				e.stopPropagation();

				if (video.paused) {
					video.play();
					videoPlayButton.classList.toggle("hidden");
					videoPauseButton.classList.toggle("hidden");
					if (videoPlayButtonBig) {
						videoPlayButtonBig.classList.toggle("hidden");
					}
				} else {
					video.pause();
					videoPlayButton.classList.toggle("hidden");
					videoPauseButton.classList.toggle("hidden");
					if (videoPlayButtonBig) {
						videoPlayButtonBig.classList.toggle("hidden");
					}
				}

			}, supportsPassiveEvents ? { passive: true } : false)

			videoPlayButton.addEventListener("click", (e) => {
				e.stopPropagation();

				video.play();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				if (videoPlayButtonBig) {
					videoPlayButtonBig.classList.toggle("hidden");
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			videoPauseButton.addEventListener("click", (e) => {
				e.stopPropagation();

				video.pause();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				if (videoPlayButtonBig) {
					videoPlayButtonBig.classList.toggle("hidden");
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			if (!videoPlayButtonBig) return;

			videoPlayButtonBig.addEventListener("click", (e) => {
				e.stopPropagation();

				video.play();
				videoPlayButton.classList.toggle("hidden");
				videoPauseButton.classList.toggle("hidden");
				videoPlayButtonBig.classList.toggle("hidden");
			}, supportsPassiveEvents ? { passive: true } : false)
		}

		/**
		 * Animate home page on load
		 * animated elements: header, video hero section and next section
		 */
		function animationOnPageLoad(iconsContainer, followMouseContainer) {
			const lottieIcons = iconsContainer.querySelectorAll(".lottie-icon");
			const header = document.querySelector("header");
			const timeline = anime.timeline();
			const homeSectionsContainers = document.querySelectorAll(".home-section");

			if (!header || !followMouseContainer || !homeSectionsContainers) return;

			followMouseContainer.classList.add("pointer-events-none");

			let lottieArray = [];

			lottieIcons.forEach((icon) => {
				const iconUrl = icon.dataset.iconUrl;

				if (!iconUrl) return;

				const lottieAnimation = lottie.loadAnimation({
					container: icon,
					autoplay: false,
					path: iconUrl,
					loop: false,
				});

				lottieArray.push(lottieAnimation);
			})

			const icons = iconsContainer.querySelectorAll(".hero-icon");

			if (!icons) return;

			timeline
				.add({
					targets: icons,
					opacity: [0, 1],
					easing: "easeInOutQuint",
					duration: 550,
					delay: anime.stagger(150, { start: 10 }),
					begin: () => {
						header.classList.remove("opacity-0");
						lottieArray.forEach((animation) => {
							animation.play();
						})
					},
				})
				.add({
					targets: followMouseContainer,
					opacity: [0, 1],
					easing: "easeOutQuint",
					duration: 800,
				}, 300)
				.add({
					targets: followMouseContainer,
					translateY: ["6rem", "0rem"],
					easing: "easeOutQuint",
					duration: 1500,
					complete: () => {
						followMouseContainer.classList.remove("pointer-events-none");
					},
				}, 500)
				.add({
					targets: header,
					translateY: ["-5rem", "0rem"],
					easing: "easeOutQuint",
					duration: 800,
				}, 800)
				.add({
					targets: homeSectionsContainers,
					opacity: [0, 1],
					easing: "easeInOutQuint",
					duration: 800,
				}, 800)
		}
	},
};
