import anime from "animejs";
import barba from "@barba/core";
import utils from "../utils";
import { unlock, clearBodyLocks } from "tua-body-scroll-lock";

const xl = utils.getMQValue("xl");
const md = utils.getMQValue("md");

export default {

	init(headerThemingFirstSection, fixScrollingSection, initSectionBasedFunctions) {
		barba.init({
			debug: true,
			transitions: [{
				name: "special-menu-links",
				from: {
					custom: ({ trigger }) => {
						if (window.matchMedia("screen and (min-width: " + xl + "px)").matches) {
							return trigger.classList && trigger.classList.contains("special-animation");
						}
					},
				},
				enter(data) {
					const header = document.querySelector("header");
					const body = document.querySelector("body");

					if (!header && !body) return;

					const allPageLinks = document.querySelectorAll("a");
					const allPageButtons = document.querySelectorAll("button");

					if (allPageLinks) {
						utils.setLinkTabIndex(allPageLinks, 0);
					}

					if (allPageButtons) {
						utils.setLinkTabIndex(allPageButtons, 0);
					}

					const menuSpaceTop = header.querySelector(".menu-space");
					const menuSpaceBottom = header.querySelector(".menu-space-bottom");
					const menuItems = header.querySelectorAll(".menu-item");
					const headerBar = header.querySelector(".header-bar");
					const hamburger = header.querySelector("#hamburger");
					const menu = header.querySelector("#menu");
					const magazineLink = header.querySelector(".mag-logo-wrapper");
					const magazineTeaserBlock = header.querySelector(".mag-teaser-block-content");
					const nextContainer = data.next.container;

					if (!menuSpaceTop && !menuSpaceBottom && !menuItems && !headerBar && !hamburger && !nextContainer && !menu) return;

					const firsSectionNext = nextContainer.querySelector("section");
					const headlineNext = nextContainer.querySelector(".project-page-headline");

					if (!firsSectionNext && !headlineNext) return;

					const headerThemingNext = headerThemingFirstSection.getHeaderTheming(firsSectionNext);
					const arrayMenuItems = [];
					let indexSpecialItem;
					let specialItem;
					let itemHeight;

					const links = header.querySelectorAll(".menu-item-link");

					if (links) {
						utils.setLinkTabIndex(links, -1);
					}

					// save all items in an array
					menuItems.forEach((item, index) => {
						arrayMenuItems.push(item);
						itemHeight = item.clientHeight;
						item.classList.add("pointer-events-none");
						if (item.classList.contains("special-animation-item")) {
							indexSpecialItem = index;
							specialItem = item;
						}
					})

					headlineNext.style.opacity = 0;

					if (!arrayMenuItems.length && !indexSpecialItem && !specialItem) return;

					const timelineTop = anime.timeline();
					const timelineBottom = anime.timeline();

					const arrayTop = arrayMenuItems.slice(0, indexSpecialItem + 1); // save all array items include special item in new array
					const arrayBottom = arrayMenuItems.slice(indexSpecialItem + 1, arrayMenuItems.length); // save remaining items in new array
					const heightTop = (itemHeight * arrayTop.length) + menuSpaceTop.clientHeight + headerBar.clientHeight - itemHeight; // calculate transform position for arrayTop, subtract height of special menu item because its not visible in the top animation because of its changing color
					const heightBottom = (itemHeight * arrayBottom.length) + menuSpaceBottom.clientHeight; // calculate transform position for arrayBottom
					const specialItemHeadline = specialItem.querySelector(".origin-text");
					const specialItemIcon = specialItem.querySelector(".menu-icon");
					const menuItemContainer = header.querySelector(".container");
					const langSwitchers = header.querySelectorAll(".lang-switcher");
					const pageContainer = document.querySelector(".page-content-wrapper");

					if (!arrayTop && !arrayBottom && !heightTop && !heightBottom && !menuItemContainer && !langSwitchers && !pageContainer && !specialItemHeadline && !specialItemIcon) return;

					const beforeLastItem = arrayTop[arrayTop.length - 2];
					const deUrl = pageContainer.dataset.deUrl;
					const enUrl = pageContainer.dataset.enUrl;
					const containerDistanceLeft = menuItemContainer.getBoundingClientRect().left;
					const icon = menuItemContainer.querySelector(".menu-icon");

					if (!beforeLastItem && !containerDistanceLeft && !icon) return;

					const iconWidth = icon.clientWidth;

					if (!iconWidth) return;

					const distanceOut = (containerDistanceLeft + iconWidth) * -1;
					langSwitchers.forEach((langSwitcher) => {
						langSwitcher.classList.add("pointer-events-none");
						const langLinks = langSwitcher.querySelectorAll(".language a");

						if (!langLinks) return;

						utils.setLinkTabIndex(langLinks, -1);
					})

					if (!distanceOut) return;

					timelineTop
						.add({
							targets: langSwitchers,
							opacity: [1, 0],
							easing: "easeOutQuint",
							duration: 100,
						}, 0)
						.add({
							targets: langSwitchers,
							opacity: [1, 0],
							easing: "easeOutQuint",
							duration: 100,
						}, 0)
						.add({
							targets: specialItemIcon,
							translateX: [0, distanceOut],
							rotate: [360, 0],
							easing: "easeOutQuint",
							duration: 1300,
						}, 0)
						.add({
							targets: magazineTeaserBlock,
							translateY: [110, 0],
							duration: 500,
							easing: "easeOutQuint",
						}, 50)
						.add({
							targets: [menuSpaceTop, arrayTop],
							translateY: [0, "-" + heightTop + "px"],
							easing: "easeOutQuint",
							duration: 1700,
							begin: () => {
								// change background color and text color of special animated item
								specialItem.classList.add(headerThemingNext);
								specialItem.classList.add("set-bg-transparent");
							},
							update: () => {
								let specialItemPosTop = Math.round(specialItemHeadline.getBoundingClientRect().top); // get position top of special item

								// change header theming if before last element reaches headerbar
								if (beforeLastItem.getBoundingClientRect().bottom <= headerBar.clientHeight) {
									header.classList.remove("open-header");
									headerThemingFirstSection.init();
								}

								// if special item pos has same position of headline on page fade out special item 
								if (specialItemPosTop <= 120) {
									specialItem.style.opacity = 0;
									headlineNext.style.opacity = 1;
								}
							},
							complete: () => {
								header.classList.remove("open-menu");
								langSwitchers.forEach((langSwitcher) => {
									langSwitcher.style.removeProperty("opacity");
								})
								menu.classList.remove("open");
								hamburger.classList.remove("close");
								menuSpaceTop.style.removeProperty("transform");
								specialItemIcon.style.removeProperty("transform");
								specialItemIcon.style.removeProperty("opacity");
								specialItem.classList.remove("set-bg-transparent");
								specialItem.classList.remove(headerThemingNext);
								magazineLink?.classList.remove("md:hidden");
								arrayTop.forEach((elem) => {
									elem.style.removeProperty("transform");
									elem.style.removeProperty("opacity");
								})
								menuItems.forEach((item) => {
									item.classList.remove("pointer-events-none");
								})
								clearBodyLocks();
								if (deUrl && enUrl) {
									langSwitchers.forEach((langSwitcher) => {
										if (langSwitcher.classList.contains("hidden")) {
											langSwitcher.classList.remove("hidden")
										}
									})
								}
								body.classList.remove("overflow-hidden");
								body.classList.remove("fixed");
							},
						}, 500)

					timelineBottom
						.add({
							targets: [arrayBottom, menuSpaceBottom],
							translateY: [0, heightBottom + "px"],
							easing: "easeOutQuint",
							duration: 1700,
							complete: () => {
								menuSpaceBottom.style.removeProperty("transform");
								arrayBottom.forEach((elem) => {
									elem.style.removeProperty("transform");
									elem.style.removeProperty("opacity");
								})
							},
						}, 500)
				},
				beforeEnter(data) {
					const children = data.next.container.children;

					if (!children) return;

					children.forEach((child) => {
						const grandChildren = child.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								grandChild.style.opacity = 0;
							}
						})
					})
				},
				afterEnter(data) {
					const pageTimeline = anime.timeline();
					const children = data.next.container.children;
					let childArray = [];

					if (!children) return;

					children.forEach((child) => {
						const grandChildren = child.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								childArray.push(grandChild)
							}
						})
					})

					pageTimeline
						.add({
							targets: childArray,
							delay: 1000,
							opacity: 1,
							easing: "easeInOutQuint",
							duration: 800,
						})
				},
				after() {
					initSectionBasedFunctions();

					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}
				},
			},
			{
				name: "menu-link",
				from: {
					custom: ({ trigger }) => {
						const header = document.querySelector("header");

						if (!header) {
							return;
						}

						if (window.matchMedia("screen and (min-width: " + xl + "px)").matches) {
							if (header.classList.contains("open-menu") && trigger.classList && !trigger.classList.contains("special-animation")) {
								return true;
							}
						} else {
							if (header.classList.contains("open-menu")) {
								return true;
							}
						}
					},
				},
				leave() {
					const header = document.querySelector("header");
					const body = document.querySelector("body");

					if (!header && !body) {
						return;
					}

					const allPageLinks = document.querySelectorAll("a");
					const allPageButtons = document.querySelectorAll("button");

					if (allPageLinks) {
						utils.setLinkTabIndex(allPageLinks, 0);
					}

					if (allPageButtons) {
						utils.setLinkTabIndex(allPageButtons, 0);
					}

					const lines = header.querySelectorAll(".menu-item-line");
					const links = header.querySelectorAll(".menu-item-link");
					const menu = header.querySelector("#menu");
					const hamburger = header.querySelector("#hamburger");
					const menuItems = header.querySelectorAll(".menu-item");
					const itemTimeline = anime.timeline();
					const langSwitchers = header.querySelectorAll(".lang-switcher");
					const magazineLink = header.querySelector(".mag-logo-wrapper");

					if (!lines && !links && !menu && !hamburger && !menuItems && !langSwitchers) {
						return;
					}

					menuItems.forEach((item) => {
						item.classList.add("pointer-events-none");
					})

					links.forEach((link) => {
						link.blur();
					})

					langSwitchers.forEach((langSwitcher) => {
						langSwitcher.classList.add("pointer-events-none");

						const langLinks = langSwitcher.querySelectorAll(".language a");

						if (!langLinks) return;

						utils.setLinkTabIndex(langLinks, -1);
					})

					utils.setLinkTabIndex(links, -1);

					itemTimeline
						.add({
							targets: links,
							translateY: [0, 120],
							easing: "easeOutQuint",
							duration: 600,
						})
						.add({
							targets: lines,
							translateX: ["0%", "100%"],
							easing: "easeOutQuint",
							duration: 600,
							complete: () => {
								header.classList.remove("open-header");
								headerThemingFirstSection.init();

								let fixedSections = document.querySelectorAll(".best-ofs-desktop");
								if (fixedSections.length) {
									fixedSections.forEach((section) => {
										fixScrollingSection.init(section);
									})
								}
							},
						})
						.add({
							targets: langSwitchers,
							opacity: 0,
							duration: 50,
							easing: "easeOutQuint",
						})
						.add({
							targets: menu,
							opacity: 0,
							easing: "easeOutQuint",
							duration: 600,
							complete: () => {
								clearBodyLocks();
								menu.classList.remove("open");
								hamburger.classList.remove("close");
								header.classList.remove("open-menu");
								body.classList.remove("overflow-hidden");
								body.classList.remove("fixed");
								magazineLink?.classList.remove("md:hidden");
								lines.forEach((line) => {
									line.style.removeProperty("transform");
								})
								menuItems.forEach((item) => {
									item.classList.remove("pointer-events-none");
								})
							},
						})
				},
				beforeEnter(data) {
					const pageContainer = data.next.container.children;

					if (!pageContainer) return;

					const sections = pageContainer[0].querySelectorAll("section");

					if (!sections) return;

					sections.forEach((section) => {
						if (!section.classList.contains("claim-section")) {
							const grandChildren = section.children;

							if (!grandChildren) return;

							grandChildren.forEach((grandChild, index) => {
								if (index === 0) {
									grandChild.style.opacity = 0;
								}
							})
						}
					})
				},
				afterEnter(data) {
					const pageTimeline = anime.timeline();
					const pageContainer = data.next.container.children;
					let childArray = [];

					if (!pageContainer) return;

					const sections = pageContainer[0].querySelectorAll("section")

					if (!sections) return;

					sections.forEach((section) => {
						if (!section.classList.contains("claim-section")) {
							const grandChildren = section.children;

							if (!grandChildren) return;

							grandChildren.forEach((grandChild, index) => {
								if (index === 0) {
									childArray.push(grandChild)
								}
							})
						}
					})

					pageTimeline
						.add({
							targets: childArray,
							delay: 1000,
							opacity: 1,
							easing: "easeInOutQuint",
							duration: 800,
						})

				},
				after() {
					const pageTimeline = anime.timeline();

					pageTimeline
						.add({
							delay: 1000,
							complete: () => {
								initSectionBasedFunctions();
							},
						})

				},
			},
			{
				name: "click-on-page-logo",
				from: {
					custom: ({ trigger }) => {
						return trigger.classList && trigger.classList.contains("page-logo");
					},
				},
				enter() {
				},
				beforeEnter(data) {
					const pageContainer = data.next.container.children;

					if (!pageContainer) return;

					const sections = pageContainer[0].children;

					if (!sections) return;

					sections.forEach((section) => {
						const grandChildren = section.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								grandChild.style.opacity = 0;
							}
						})
					})
				},
				after() {
					headerThemingFirstSection.init();
					initSectionBasedFunctions();
					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}
				},
				afterEnter(data) {
					const pageTimeline = anime.timeline();
					const pageContainer = data.next.container.children;
					let childArray = [];

					if (!pageContainer) return;

					const sections = pageContainer[0].children;

					if (!sections) return;

					sections.forEach((section) => {
						const grandChildren = section.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								childArray.push(grandChild)
							}
						})
					})

					pageTimeline
						.add({
							targets: childArray,
							delay: 1000,
							opacity: 1,
							easing: "easeInOutQuint",
							duration: 800,
						})
				},
			},
			{
				name: "job-detail-links",
				from: {
					custom: ({ trigger }) => {
						if (window.matchMedia("screen and (min-width: " + md + "px)").matches) {
							return trigger.classList && trigger.classList.contains("job-detail-link");
						}
					},
				},
				leave(data) {
					const activeLink = data.trigger;
					const currentJobOffer = activeLink.firstElementChild;
					activeLink.classList.add("pointer-events-none");

					if (!currentJobOffer) return;

					currentJobOffer.classList.add("pointer-events-none");
					currentJobOffer.classList.remove("magic-mouse");
					currentJobOffer.classList.add("cursor-default");

					const currentJobOfferOverlay = currentJobOffer.querySelector(".job-offer-overlay");
					const currentJobOfferContent = currentJobOffer.firstElementChild;

					if (!currentJobOfferOverlay && !currentJobOfferContent) return;

					const jobDetailTimeline = anime.timeline();

					// wait until animation completed then switch to next life cycle step
					return new Promise(resolve => {
						const header = document.querySelector("header");
						const content = document.querySelector(".content");

						if (!header && !content) return;

						header.classList.remove("z-10");
						header.classList.add("-z-1");
						content.classList.add("overflow-hidden");

						if (window.matchMedia("screen and (min-width: " + md + "px)").matches) {

							// Animation for large devices
							jobDetailTimeline
								.add({
									targets: [currentJobOffer, currentJobOfferOverlay],
									backgroundColor: "#111111",
									easing: "easeOutQuint",
									duration: 50,
								})
								.add({
									targets: currentJobOfferOverlay,
									scale: [1, 8],
									easing: "easeInOutQuint",
									duration: 500,
								}, "-=50")
								.add({
									targets: currentJobOfferContent,
									opacity: [1, 0],
									easing: "easeOutQuint",
									duration: 500,
									complete: () => {
										header.classList.add("z-10");
										header.classList.remove("-z-1");
										content.classList.remove("overflow-hidden");
										resolve();
									},
								}, "-=100")
						} else {

							// Animation for small devices
							jobDetailTimeline
								.add({
									targets: currentJobOfferContent,
									opacity: [1, 0],
									easing: "easeOutQuint",
									duration: 100,

								})
								.add({
									targets: currentJobOffer,
									backgroundColor: "#111111",
									easing: "easeOutQuint",
									duration: 80,
								}, "-=100")
								.add({
									targets: currentJobOffer,
									scale: [1, 8],
									easing: "easeInOutQuint",
									duration: 300,
									complete: () => {
										header.classList.add("z-10");
										header.classList.remove("-z-1");
										content.classList.remove("overflow-hidden");
										resolve();
									},
								}, "-=50")
						}

					});
				},
				beforeEnter(data) {
					const animationHelpDiv = document.querySelector(".animation-help-div");
					const children = data.next.container.children;
					const timeline = anime.timeline();

					if (!children && !animationHelpDiv) return;

					children.forEach((child) => {
						const grandChildren = child.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								grandChild.style.opacity = 0;
							}
						})
					})

					// animate colored div to prevent color flicker
					timeline
						.add({
							targets: animationHelpDiv,
							opacity: [0, 1],
							easing: "easeOutQuint",
							duration: 10,
						})
				},
				enter(data) {
					const pageTimeline = anime.timeline();
					const children = data.next.container.children;
					let childArray = [];

					if (!children) return;

					children.forEach((child) => {
						const grandChildren = child.children;

						if (!grandChildren) return;

						grandChildren.forEach((grandChild, index) => {
							if (index === 0) {
								childArray.push(grandChild)
							}
						})
					})

					pageTimeline
						.add({
							targets: childArray,
							opacity: 1,
							easing: "easeInOutQuint",
							duration: 200,
						})
				},
				after() {
					const animationHelpDiv = document.querySelector(".animation-help-div");
					const timeline = anime.timeline();

					if (!animationHelpDiv) return;

					headerThemingFirstSection.init();

					initSectionBasedFunctions();

					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}

					timeline
						.add({
							targets: animationHelpDiv,
							opacity: [1, 0],
							easing: "easeOutQuint",
							duration: 100,
							delay: 100,
						})
				},
			},
			{
				name: "next-project-button",
				from: {
					custom: ({ trigger }) => {
						return trigger.classList && trigger.classList.contains("button-next-project");
					},
				},
				leave(data) {
					const currentContainer = data.current.container;
					const currentPageTimeline = anime.timeline();

					if (!currentContainer) return;

					const currentNextProject = currentContainer.querySelector(".next-entry-container");

					if (!currentNextProject) return;

					return new Promise(resolve => {
						currentPageTimeline
							.add({
								targets: currentNextProject,
								opacity: [1, 0],
								easing: "easeOutQuint",
								duration: 180,
								complete: () => {
									resolve();
								},
							})
					})

				},
				beforeEnter(data) {
					const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
					const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

					if (!isSafari && !iOS) {
						const nextContainer = data.next.container;
	
						if (!nextContainer) return;
	
						const pageContent = nextContainer.querySelector(".page-content-wrapper");
	
						if (!pageContent) return;
	
						pageContent.style.opacity = 0;
					}

				},
				enter() {
				},
				after() {
					headerThemingFirstSection.init();
					initSectionBasedFunctions();

					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}
				},
			},
			{
				name: "close-contact-modal",
				from: {
					custom: ({ trigger }) => {
						return trigger.classList && trigger.classList.contains("home-button-modal");
					},
				},
				enter() {
				},
				after() {
					headerThemingFirstSection.init();
					initSectionBasedFunctions();

					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}

					const timeline = anime.timeline();
					const footer = document.querySelector("footer");

					if (!footer) return;

					const contactPageFormsContainer = footer.querySelector(".contact-page-forms");
					const formOverlay = footer.querySelector(".form-overlay");
					const closeIcon = footer.querySelector(".close-icon-div");
					const textAnimationLines = footer.querySelectorAll(".text-animation-line");
					const homeButton = footer.querySelector(".home-button-modal");
					const forms = footer.querySelectorAll("form");

					if (!formOverlay && !contactPageFormsContainer && !textAnimationLines && !homeButton && !forms) return;

					const successAnimationDiv = formOverlay.querySelector(".success-animation-div");
					const modal = formOverlay.querySelector(".modal");

					if (!successAnimationDiv && !modal) return;

					successAnimationDiv.classList.remove("z-20");
					successAnimationDiv.classList.add("opacity-0");

					timeline
						.add({
							targets: formOverlay,
							opacity: [1, 0],
							easing: "easeInOutQuint",
							duration: 300,
							complete: () => {
								formOverlay.classList.add("pointer-events-none");
								contactPageFormsContainer.classList.add("hidden");
								unlock(formOverlay); // Enable body scroll
								// enableBodyScroll(formOverlay);
								closeIcon.removeAttribute("style");
								homeButton.removeAttribute("style");
								homeButton.classList.add("pointer-events-none");
								modal.removeAttribute("style");
								textAnimationLines.forEach((line) => {
									line.removeAttribute("style");
								})
								forms.forEach((form) => {
									form.removeAttribute("style");
								})
							},
						})
				},
			},
			{
				name: "all-default-links",
				beforeEnter() {
					window.scrollTo(0, 0);

					const body = document.querySelector("body");

					if (!body) return;
					
					if (body.classList.contains("overflow-hidden")) {
						body.classList.remove("overflow-hidden");
					}
				},
				enter() {
				},
				after() {
					headerThemingFirstSection.init();
					initSectionBasedFunctions();

					let fixedSections = document.querySelectorAll(".best-ofs-desktop");
					if (fixedSections.length) {
						fixedSections.forEach((section) => {
							fixScrollingSection.init(section);
						})
					}
				},
			}],
		});

		// scroll to top after barba transition/page change
		barba.hooks.enter(() => {
			window.scrollTo(0, 0);
		});
	},
}