import React from "react";
import ReactDOM from "react-dom";
import Contact from "./Contact";
import { contactStore } from "./ContactStore";
import { toJS } from "mobx";

export default {

	init() {

		// if you have a component which is not on each page available and you would like to lazy load
		// check the DOM for it, then lazy load corresponding react component
		// provide Suspense fallback (loading state)

		const reactForm = document.getElementById("react-form");

		if (reactForm) {
			let contactForm;
			let buttonLabel;

			if (reactForm.dataset.answers) {
				contactForm = JSON.parse(reactForm.dataset.answers);
			}

			if (reactForm.dataset.buttonLabel) {
				buttonLabel = reactForm.dataset.buttonLabel;
			}

			if (contactForm) {
				contactStore.contactForm = contactForm;
			}

			if (contactForm && buttonLabel) {
				ReactDOM.render(
					<React.StrictMode>
						<Contact buttonLabel={buttonLabel} />
					</React.StrictMode>,
					reactForm
				);
			}

		}
	},
}
