let storedMQ = 0;
let mediaQueries = {
	xs: 375,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	twoxl: 1536,
}

export default {

	/**
	 * Get value of tailwind media queries by passing breakpoint term
	 * 
	 * @param {string} mq 
	 * @returns 
	 */
	getMQValue(mq) {
		if (mq == "xs") {
			return mediaQueries.xs;
		}
		if (mq == "sm") {
			return mediaQueries.sm;
		}
		if (mq == "md") {
			return mediaQueries.md;
		}
		if (mq == "lg") {
			return mediaQueries.lg;
		}
		if (mq == "xl") {
			return mediaQueries.xl;
		}
		if (mq == "twoxl") {
			return mediaQueries.twoxl;
		}
	},

	throttle(callback, limit) {
		console.log("throttle")
		let wait = false;
		return function () {
			if (!wait) {
				callback.call();
				wait = true;
				setTimeout(function () {
					wait = false;
				}, limit);
			}
		}
	},

	debounce(func, wait, immediate) {
		console.log("debounce");

		let timeout;
		return function () {
			let context = this, args = arguments;
			let later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	},

	/**
	 * Check if tailwind media query changed 
	 * 
	 * @returns boolean
	 */
	checkIfMediaQueryChanged() {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let currentMQ = 0;

		if (storedMQ == 0) {
			storedMQ = width;
		}

		if (width <= mediaQueries.xs) {
			currentMQ = mediaQueries.xs;
		} else if (width >= mediaQueries.xs && width <= mediaQueries.sm) {
			currentMQ = mediaQueries.sm;
		} else if (width >= mediaQueries.sm && width <= mediaQueries.md) {
			currentMQ = mediaQueries.md;
		} else if (width >= mediaQueries.md && width <= mediaQueries.lg) {
			currentMQ = mediaQueries.lg;
		} else if (width >= mediaQueries.lg && width <= mediaQueries.xl) {
			currentMQ = mediaQueries.xl;
		} else {
			currentMQ = mediaQueries.twoxl;
		}

		if (currentMQ != storedMQ) {
			storedMQ = currentMQ;
			return true;
		}
		return false;
	},

	/**
	 * Set tab index to elements
	 */
	setLinkTabIndex(links, index) {
		links.forEach(function (link) {
			link.setAttribute("tabIndex", index);
		});
	},
}




