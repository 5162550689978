export default {
    init() {
        const jobDetailsPage = document.querySelectorAll(".job-details-page");
        const windowWidth = window.innerWidth;

        if (jobDetailsPage && windowWidth <= 640) {
            jobDetailsPage.forEach(page => {
                const stickyArrowButton = page.querySelector(".sticky-arrow-button");
                const arrowButton = page.querySelector(".arrow-button");

                if (stickyArrowButton && arrowButton) {
                    window.onscroll = function () {
                        //to get the position of the sticky arrow button div on the window
                        const clientRect = arrowButton.getBoundingClientRect();
                        const clientRectTop = clientRect.top;

                        if (clientRectTop - window.innerHeight <= 0) {
                            stickyArrowButton.style.opacity = "0";
                        } else {
                            stickyArrowButton.style.opacity = "1";
                        }
                    };
                }
            });
        }
    },
}
