import utils from "../utils";
import { supportsPassiveEvents } from "detect-it";

export default {

	init(section) {
		const lg = utils.getMQValue("lg");
		const parentContainer = section.querySelector(".follow-mouse-container");

		if (!parentContainer) return;

		const followMouseElem = parentContainer.querySelector(".follow-mouse");

		if (!followMouseElem) return;


		// only start following functionality on large devices
		if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
			followMouse(followMouseElem, parentContainer);
			resetStyleParentContainer(followMouseElem);
		}

		let xFollow = 0;
		let yFollow = 0;

		// animate();


		/**
		 * Change position of video container depending on mouse position inside parent container
		 */
		function followMouse(followMouseElem, parentContainer) {
			followMouseElem.classList.add("follow-mouse-true");

			const parentOffset = followMouseElem.getBoundingClientRect();
			const body = document.body;

			if (!parentOffset || !body) return;

			// set offset directions of parent container
			let offset = {
				top: parentOffset.top + body.scrollTop,
				left: parentOffset.left + body.scrollLeft,
			};

			if (!offset) return;

			offset.right = (offset.left + parentOffset.width);
			offset.bottom = (offset.top + parentOffset.height);

			// Set center of following element
			let followMouseCenter = {
				x: followMouseElem.getBoundingClientRect().width / 2,
				y: followMouseElem.getBoundingClientRect().height / 2,
			};

			// follow mouse inside parent container
			parentContainer.addEventListener("mousemove", function (e) {
				if (!parentContainer.classList.contains("not-following")) {
					throttle(updateFollowElemPos(followMouseCenter, e, offset), 8000)
					// updateFollowElemPos(followMouseCenter, e, offset)
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			// reset position of following element
			parentContainer.addEventListener("mouseleave", function () {
				if (!parentContainer.classList.contains("not-following")) {
					followMouseElem.style.removeProperty("transform");
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			section.addEventListener("mouseleave", function () {
				if (!parentContainer.classList.contains("not-following")) {
					followMouseElem.style.removeProperty("transform");
				}
			}, supportsPassiveEvents ? { passive: true } : false)


		}

		/**
		 * change position of following element depending on mousemove
		 */
		function updateFollowElemPos(followMouseCenter, mouseEvent, parentOffset) {
			if (mouseEvent.clientX <= parentOffset.left + 150) {
				xFollow = "-30";
			} else if (mouseEvent.clientX >= parentOffset.left + followMouseElem.getBoundingClientRect().width - 150) {
				xFollow = "30";
			} else {
				xFollow = mouseEvent.offsetX - followMouseCenter.x;
			}

			if (mouseEvent.clientY <= parentOffset.top + 200) {
				yFollow = "-20";
			} else if (mouseEvent.clientY >= parentOffset.top + followMouseElem.getBoundingClientRect().height - 150) {
				yFollow = "20";
			} else {
				yFollow = mouseEvent.offsetY - followMouseCenter.y;
			}

			followMouseElem.style.transform = `translate3d(${xFollow}px, ${yFollow}px, 0)`;
		}


		// function animate() {
		// 	followMouseElem.style.transform = `translate3d(${xFollow}px, ${yFollow}px, 0)`;

		// 	requestAnimationFrame(animate);
		// }


		/**
		 * Call a function once in a specified time period.
		 */
		const throttle = (func, limit) => {
			let lastFunc;
			let lastRan;
			return function () {
				const context = this;
				const args = arguments;
				if (!lastRan) {
					func.apply(context, args)
					lastRan = Date.now();
				} else {
					clearTimeout(lastFunc);
					lastFunc = setTimeout(function () {
						if ((Date.now() - lastRan) >= limit) {
							func.apply(context, args);
							lastRan = Date.now();
						}
					}, limit - (Date.now() - lastRan));
				}
			}
		}


		/**
		 * Resets transform attribute after clicking on video
		 */
		function resetStyleParentContainer(followMouseElem) {
			const previewVideo = followMouseElem.querySelector(".preview-video");

			if (!previewVideo) return;

			previewVideo.addEventListener("click", function () {
				followMouseElem.style.removeProperty("transform");
			}, supportsPassiveEvents ? { passive: true } : false)
		}
	},

}


