import { unlock, lock } from "tua-body-scroll-lock";
import anime from "animejs";
import lottie from "lottie-web";
import { supportsPassiveEvents } from "detect-it";
import utils from "../utils";

export default {
    init(header, headerThemingFirstSection) {
        const menu = header.querySelector("#menu");
        const hamburger = header.querySelector("#hamburger");
        const magazineLink = header.querySelector(".mag-logo-wrapper");
        const magazineTeaserBlock = header.querySelector(".mag-teaser-block-content");
        const body = document.querySelector("body");

        if (!menu || !hamburger || !body) {
            return;
        }

        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty("--app-height", `${window.innerHeight}px`)
        }

        // Adapt menu height if window size changes
        window.addEventListener("resize", () => {
            appHeight();
        }, supportsPassiveEvents ? { passive: true } : false);


        // Open/close menu by clicking on hamburger icon
        hamburger.addEventListener("click", function () {
            appHeight();
            const mainTimeline = anime.timeline();
            const menuItems = header.querySelectorAll(".menu-item");
            const langSwitchers = header.querySelectorAll(".lang-switcher")
            const pageContainer = document.querySelector(".page-content-wrapper");

            if (!menuItems || !pageContainer) {
                return;
            }

            const deUrl = pageContainer.dataset ? pageContainer.dataset.deUrl : null;
            const enUrl = pageContainer.dataset ? pageContainer.dataset.enUrl : null;


            // close menu
            if (menu.classList.contains("open")) {
                headerThemingFirstSection.init(); // set theming to header
                const allPageLinks = document.querySelectorAll("a");
                const allPageButtons = document.querySelectorAll("button");

                if (allPageLinks) {
                    utils.setLinkTabIndex(allPageLinks, 0);
                }

                if (allPageButtons) {
                    utils.setLinkTabIndex(allPageButtons, 0);
                }

                const lines = header.querySelectorAll(".menu-item-line");
                const links = header.querySelectorAll(".menu-item-link");
                const closeTimeline = anime.timeline();
                langSwitchers.forEach((langSwitcher) => {
                    langSwitcher.classList.add("pointer-events-none");

                    const langLinks = langSwitcher.querySelectorAll(".language a");

                    if (!langLinks) return;

                    utils.setLinkTabIndex(langLinks, -1);
                })

                if (!lines || !links) {
                    return;
                }

                utils.setLinkTabIndex(links, -1);

                closeTimeline
                    .add({
                        targets: links,
                        translateY: [0, 120],
                        easing: "easeOutQuint",
                        duration: 600,
                    }, 0)
                    .add({
                        targets: lines,
                        translateX: ["0%", "100%"],
                        easing: "easeOutQuint",
                        duration: 600,
                    }, 100)
                    .add({
                        targets: langSwitchers,
                        opacity: 0,
                        duration: 50,
                        easing: "easeOutQuint",
                    }, 50)
                    .add({
                        targets: magazineTeaserBlock,
                        translateY: [0, 110],
                        duration: 50,
                        easing: "easeOutQuint",
                    }, 50)
                    .add({
                        targets: "#menu",
                        opacity: 0,
                        easing: "easeOutQuint",
                        duration: 50,
                        complete: () => {
                            unlock(header);
                            removeOpenClasses();
                            header.classList.remove("open-header");
                            lines.forEach((line) => {
                                line.style.removeProperty("transform");
                            })
                            if (deUrl || enUrl) {
                                langSwitchers.forEach((langSwitcher) => {
                                    if (langSwitcher.classList.contains("hidden")) {
                                        langSwitcher.classList.remove("hidden")
                                    }
                                })
                            }
                        },
                    });

            } else {
                // open menu
                const allPageLinks = document.querySelectorAll("a");
                const allPageButtons = document.querySelectorAll("button");

                if (allPageLinks) {
                    utils.setLinkTabIndex(allPageLinks, -1);
                }

                if (allPageButtons) {
                    utils.setLinkTabIndex(allPageButtons, -1);
                }

                const pageLogo = header.querySelector(".page-logo");

                utils.setLinkTabIndex([hamburger, pageLogo], 0);

                lock(header);
                menu.classList.add("overflow-hidden");
                addOpenClasses();
                // remove theming class of header
                header.classList.forEach((headerClass) => {
                    if (headerClass.match(/^theming-/)) {
                        header.classList.remove(headerClass)
                    }
                })
                header.classList.add("open-header");
                hamburger.classList.add("pointer-events-none");
                magazineLink?.classList.add("md:hidden");
                langSwitchers.forEach((langSwitcher) => {
                    langSwitcher.classList.remove("pointer-events-none");
                    const langLinks = langSwitcher.querySelectorAll(".language a");

                    if (!langLinks) return;

                    utils.setLinkTabIndex(langLinks, 0);
                })
                if (deUrl || enUrl) {
                    langSwitchers.forEach((langSwitcher) => {
                        if (langSwitcher.classList.contains("hidden")) {
                            langSwitcher.classList.remove("hidden")
                        }
                    })
                }

                mainTimeline
                    .add({
                        targets: "#menu",
                        opacity: 1,
                        easing: "easeOutQuint",
                    })
                    .add({
                        targets: langSwitchers,
                        opacity: 1,
                        duration: 50,
                        easing: "easeOutQuint",
                    })
                    .add({
                        targets: magazineTeaserBlock,
                        translateY: [120, 0],
                        duration: 50,
                        easing: "easeOutQuint",
                    }, 50)

                const openTimeline = anime.timeline();

                const lines = header.querySelectorAll(".menu-item-line");
                const links = header.querySelectorAll(".menu-item-link");

                if (!lines || !links) return;

                utils.setLinkTabIndex(links, 0);

                openTimeline
                    .add({
                        targets: lines,
                        width: [0, "100%"],
                        easing: "easeInOutQuint",
                        duration: 900,
                        delay: anime.stagger(150, { start: 0 }),
                    })
                    .add({
                        targets: magazineTeaserBlock,
                        translateY: [110, 0],
                        duration: 500,
                        easing: "easeOutQuint",
                    }, 50)
                    .add({
                        targets: links,
                        translateY: [120, 0],
                        easing: "easeInOutQuint",
                        duration: 600,
                        delay: anime.stagger(120, { start: 0 }),
                        complete: () => {
                            menuItems.forEach((item) => {
                                item.classList.remove("pointer-events-none");
                            })
                            hamburger.classList.remove("pointer-events-none");
                            hamburger.classList.add("close");
                            menu.classList.remove("overflow-hidden");
                            if (magazineLink) {
                                magazineLink.classList.add("md:hidden");
                            }
                        },
                    }, 200);
            }
        }, supportsPassiveEvents ? { passive: true } : false);

        /**
         * Remove open classes of each affected element
         */
        function removeOpenClasses() {
            menu.classList.remove("open");
            hamburger.classList.remove("close");
            header.classList.remove("open-menu");
            magazineLink?.classList.remove("md:hidden");
        }

        /**
         * Add open classes to affected elements
         */
        function addOpenClasses() {
            menu.classList.add("open");
            header.classList.add("open-menu");
            magazineLink?.classList.add("md:hidden");
        }

        const animationContainers = document.querySelectorAll(
            ".lottie-player"
        );
        if (!animationContainers) return;

        animationContainers.forEach(function (animationContainer) {
            const animationUrl = animationContainer.dataset.animationUrl;
            if (!animationUrl) return;

            const lottieAnimation = lottie.loadAnimation({
                container: animationContainer,
                autoplay: true,
                path: animationUrl,
                loop: true,
                rendererSettings: {
                    className: "w-auto",
                },
            });

            if (!lottieAnimation) {
                return;
            }
        });

    },
}