import utils from "../utils";
import ScrollMagic from "scrollmagic";
import anime from "animejs";
import { supportsPassiveEvents } from "detect-it";

let origTextArray = [];

export default {

	/**
	 * Call function to split elements on load and resize.
	 * Only split elements on resize if media query changes or on xs devices
	 * 
	 * @param {array of divs} elements 
	 */
	init() {

		// remove old origin text from previous page
		if (origTextArray.length) {
			origTextArray = [];
		}

		let elements = document.getElementsByClassName("split-claim");

		const sm = utils.getMQValue("sm");

		elements.forEach(element => {
			this.splitTextIntoLines(element);
		});

		window.addEventListener("resize", () => {
			if (utils.checkIfMediaQueryChanged()) {
				this.resizeSplitTextIntoLines(elements);
			} else if (window.matchMedia("screen and (max-width: " + sm + "px)").matches) {
				this.resizeSplitTextIntoLines(elements);
			}
		}, supportsPassiveEvents ? { passive: true } : false);

		this.animateText();
	},

	/**
	 * Saves each word of the div in a span. Then compares the position of all divs.
	 * divs with the same positions (distance to parent element) are saved in new line span.
	 * 
	 * @param {div} element 
	 */
	splitTextIntoLines(element) {
		const originalText = element.textContent;
		const elementWords = originalText.split(" ");
		const divs = [];

		element.textContent = " ";
		origTextArray.push(originalText); // save the original text in array so we can access it later

		elementWords.forEach(word => {
			const newDiv = document.createElement("div");
			newDiv.classList.add("inline");
			newDiv.textContent = word + " "; // add space at the end of a word

			element.appendChild(newDiv);

			// Create an Index value, which stores the current element's distance from the top of the parent 
			// elements with the same Index value notify us that they are on the same line of text
			// offsetTop = the number of pixels from the top of the closest relatively positioned parent element
			// scrollTop = gets the number of pixels that an element's content is scrolled vertically.  
			const index = newDiv.offsetTop - newDiv.scrollTop;


			// if the divs index value is equal to 'undefined' make it an empty value instead
			if (divs[index] === undefined) {
				divs[index] = "";
			}

			// use the Index value to decide which divs to merge together (ones with the same value get merged)
			divs[index] += newDiv.textContent;

			// At this point each seperate word is in it's own span with a trailing whitespace
			// Clear the original elements text content
			element.textContent = "";

			// now re-populate the element with the newly merged divs
			divs.forEach(div => {
				if (div !== undefined) {
					const divLine = document.createElement("div");
					divLine.classList.add("split-item");
					divLine.classList.add("inline");

					const divP = document.createElement("span");
					divP.classList.add("inline");
					// add the text content and send the newly merged divs into the div
					divP.textContent = div;
					divLine.appendChild(divP);
					element.appendChild(divLine);
				}
			});
		})
	},

	/**
	 * Set back original text and split elements again
	 * 
	 * @param {divs} elements 
	 */
	resizeSplitTextIntoLines(elements) {
		for (let i = 0; i < elements.length; i++) {
			const el = elements[i];

			// empty the element
			el.innerHTML = "";
			// send in the original text stored in the array
			const orig = origTextArray[i];
			el.textContent = orig;

			this.splitTextIntoLines(el);
		}
	},

	/**
	 * Animate text if section is 40% visible
	 * Animate text part with animejs
	 */
	animateText() {
		const controller = new ScrollMagic.Controller();
		const sections = document.getElementsByClassName("claim-section");

		if (!sections) {
			return;
		}

		sections.forEach((section) => {
			const scene = new ScrollMagic.Scene({ triggerElement: section, triggerHook: 0.9 })
				.addTo(controller)

			const timeline = anime.timeline();
			const headline = section.querySelector(".headline");
			const lines = section.querySelectorAll(".split-item");
			const textAndButton = section.querySelector(".text-button-container");
			const lineParagraph = section.querySelectorAll(".split-item span") || null;

			if (lines) {
				lines.forEach((line) => {
					line.classList.add("set-block");
				})
			}

			scene.on("enter", function () {
				const elementContainer = section.querySelector(".split-claim-container");

				if (elementContainer) {
					elementContainer.classList.remove("opacity-0");
				}

				if (!section.classList.contains("animation-played")) {
					timeline
						.add({
							targets: headline,
							translateX: [100, 0],
							opacity: 1,
							easing: "easeOutQuint",
							duration: 700,
							complete: () => {
								section.classList.add("animation-played");
							},
						})
						.add({
							targets: lineParagraph,
							translateY: [72, 0],
							easing: "easeOutQuint",
							delay: anime.stagger(100),
						}, "-=600")
						.add({
							targets: textAndButton,
							translateY: [50, 0],
							opacity: 1,
							easing: "easeOutQuint",
						}, 600)
				}
			})
		})

	},
}