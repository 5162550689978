import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { contactStore } from "./ContactStore";
import { scrollTo } from "./scrollTo";
import Anime from "react-anime";

export type ISubmitButtonProps = {
	buttonLabel: string;
}

const NextStep = observer((props: ISubmitButtonProps) => {

	useEffect(() => {
		const activeId = contactStore.contactForm?.activeId;
		if (activeId != undefined) {
			scrollTo(activeId, 800);
		}
	});

	return (
		<>
			{contactStore.contactForm?.answers && contactStore.contactForm?.answers.map((answer, index) => {
				return (
					<div key={index}>
						{answer.isSelected && answer.inputs.length > 0 &&
							answer.inputs.map((input, index) => {
								return (
									<Anime
										easing="easeOutQuint"
										duration={800}
										delay={300}
										opacity={[0, 1]}
										className="single-choice-wrapper"
										key={index}
									>
										<div id={input.uid}>
											<h2 className="mb-3 text-white uppercase font-esrebond-regular text-form-question-mobile md:text-form-question md:mb-4">{input.question}</h2>

											<div className="answers mb-14 md:mb-20">
												{input.answers && input.answers.map((inputAnswer, index) => {
													return (
														<Anime
															easing="easeOutQuint"
															duration={800}
															delay={300}
															opacity={[0, 1]}
															className="next-step-wrapper"
															key={index}
														>
															<div className={(input.isActive ? "active" : "opacity-50")}>
																{inputAnswer.type == "singleChoice" &&
																	<a key={index} onClick={() => { contactStore.selectSubAnswer(inputAnswer.uid) }} className={(inputAnswer.isSelected ? "selected line-through " : "") + "answer block font-esrebond-regular text-h4-mobile md:text-h3 mb-6 md:mb-4 text-stone cursor-pointer"}>
																		{inputAnswer.value}
																	</a>
																}
																{inputAnswer.type == "textInput" &&
																	<>
																		<textarea
																			rows={6}
																			id={"input-" + (input.uid)}
																			className="w-full h-40 px-4 py-6 text-base bg-transparent border-t-0 border-b-4 border-l-0 border-r-0 md:h-72 md:w-10/12 lg:w-8/12 font-esrebond-regular md:text-form-placeholder text-stone mb-14 md:mb-20"
																		>
																		</textarea>
																	</>
																}
															</div>
														</Anime>
													)
												})}
											</div>
											{input.nextStep.showNextStep && input.nextStep.nextStepQuestion &&
												<div className="mb-14 md:mb-20" id={input.nextStep.uid}>
													<h2 className="mb-3 text-white uppercase font-esrebond-regular text-form-question-mobile md:text-form-question md:mb-4">{input.nextStep.nextStepQuestion}</h2>
													<textarea
														rows={6}
														id={"input-" + (input.nextStep.uid)}
														className="w-full h-40 px-4 py-6 text-base bg-transparent border-t-0 border-b-4 border-l-0 border-r-0 md:h-72 md:w-10/12 lg:w-8/12 font-esrebond-regular md:text-form-placeholder text-stone mb-14 md:mb-20"
													>
													</textarea>
												</div>
											}
										</div>
									</Anime>
								)
							})
						}
					</div>
				)
			})}
		</>
	)
})

export default NextStep;