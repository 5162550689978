import ScrollMagic from "scrollmagic";
import anime from "animejs";

export default {
	init(section) {
		if (!section) return;

		const timeline = anime.timeline();
		const controllerCaseStudy = new ScrollMagic.Controller();

		if (!controllerCaseStudy) return;

		const sceneCaseStudy = new ScrollMagic.Scene({ triggerElement: section, triggerHook: "onEnter" })
			.addTo(controllerCaseStudy)

		const image = section.querySelector("picture");
		const video = section.querySelector(".video-container");

		if (!sceneCaseStudy) return;

		sceneCaseStudy.on("enter", function () {
			if (!section.classList.contains("animation-played")) {
				if (video) {
					timeline
						.add({
							targets: video,
							opacity: [0, 1],
							translateY: ["5rem", 0],
							easing: "easeOutQuint",
							duration: 900,
							delay: 200,
							complete: () => {
								section.classList.add("animation-played");
							},
						})
				}
				if (image) {
					timeline
						.add({
							targets: image,
							opacity: [0, 1],
							translateY: ["5rem", 0],
							easing: "easeOutQuint",
							duration: 900,
							delay: 200,
							complete: () => {
								section.classList.add("animation-played");
							},
						})
				}
			}
		})

		// let currentPos = window.pageYOffset;
		// const maxSkew = 1;
		// const img = section.querySelector("picture img")

		// function skewEffect() {
		// 	const newPos = window.pageYOffset;
		// 	console.log("skew effect")

		// 	if (newPos != currentPos) {
		// 		const dif = newPos - currentPos;

		// 		let skew = dif * .2;

		// 		if (skew > maxSkew || skew < -maxSkew) {
		// 			if (skew > 0) {
		// 				skew = maxSkew;
		// 			} else if (skew < 0) {
		// 				skew = -maxSkew;
		// 			}
		// 		}
		// 		if (img) {
		// 			img.style.transform = `skewY(${skew}deg)`;
		// 		}
		// 		currentPos = newPos;
		// 	}
		// 	requestAnimationFrame(skewEffect);

		// }

		// skewEffect();

		// sceneCaseStudy.on("update", function (e) {
		// 	let scrollDirection = e.target.controller().info("scrollDirection");
		// 	// const scrollTimeline = anime.timeline();

		// 	// console.log(e.target.controller().info())

		// 	if (scrollDirection == "FORWARD") {
		// 		console.log("forward")
		// 		// if (image) {
		// 		// 	scrollTimeline
		// 		// 		.add({
		// 		// 			targets: image,
		// 		// 			skew: "1deg",
		// 		// 			easing: "easeOutQuint",
		// 		// 			duration: 100,
		// 		// 		})
		// 		// }
		// 	} else if (scrollDirection == "REVERSE") {
		// 		console.log("reverse")
		// 		// if (image) {
		// 		// 	scrollTimeline
		// 		// 		.add({
		// 		// 			targets: image,
		// 		// 			skew: "-1deg",
		// 		// 			easing: "easeOutQuint",
		// 		// 			duration: 100,
		// 		// 		})
		// 		// }
		// 	} else {
		// 		// if (image) {
		// 		// 	scrollTimeline
		// 		// 		.add({
		// 		// 			targets: image,
		// 		// 			skew: "0",
		// 		// 			easing: "easeOutQuint",
		// 		// 			duration: 100,
		// 		// 		})
		// 		// }
		// 	}
		// })
	},
}
