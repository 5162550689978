import React from "react";
import { contactStore } from "./ContactStore";
import { observer } from "mobx-react-lite";

const SingleChoice = observer(() => {
	return (
		<>
			<div className={(contactStore.contactForm?.isActive ? "active" : "opacity-50") + " single-choice mb-14 md:mb-20"}>
				<h2 className="mb-3 text-white uppercase font-esrebond-regular text-form-question-mobile md:text-form-question md:mb-4">{contactStore.contactForm?.question}</h2>
				<div className="answers">
					{contactStore.contactForm?.answers && contactStore.contactForm?.answers.map((answer, index) => {
						return (
							<a key={index} id={answer.uid} href={"#" + (index)} onClick={() => { contactStore.selectAnswer(answer.uid) }} className={(answer.isSelected ? "selected line-through " : "") + "answer block font-esrebond-regular text-h4-mobile md:text-h3 mb-6 md:mb-4 text-stone cursor-pointer"}>
								{answer.answer}
							</a>
						)
					})}
				</div>
			</div>
		</>
	)
});

export default SingleChoice;