import ScrollMagic from "scrollmagic";
import anime from "animejs";

let controller;

export default {

	init(section) {
		if (!section) {
			return;
		}
		if (controller) {
			controller = controller.destroy(true);
		}

		this.setSliderHeight(section);
		this.animateDesktopSection(section);

	},

	getSliderHeight(section) {
		const rowContainer = section.querySelector(".container");

		if(!rowContainer) return;

		const headlineRow = rowContainer.querySelector(".headline-row");
		const sliderRow = rowContainer.querySelector(".slider-row");

		if(!headlineRow || !sliderRow) return;

		let newSliderHeight = rowContainer.offsetHeight - headlineRow.offsetHeight;

		return newSliderHeight;
	},

	setSliderHeight(section) {
		const rowContainer = section.querySelector(".container");

		if(!rowContainer) return;

		const headlineRow = rowContainer.querySelector(".headline-row");
		const sliderRow = rowContainer.querySelector(".slider-row");

		if(!headlineRow || !sliderRow) return;

		let newSliderHeight = rowContainer.offsetHeight - headlineRow.offsetHeight;

		sliderRow.style.height = newSliderHeight + "px";
	},

	animateDesktopSection(section) {
		const projectSlides = section.querySelectorAll(".project-slide");
		const projectContainer = section.querySelector(".project-container");
		const newSliderHeight = this.getSliderHeight(section);

		if (!projectSlides || !projectContainer || !newSliderHeight) return;

		const slidesCount = projectSlides.length;

		// remove opacity 0 after project container width is calculated
		projectSlides.forEach(slide => {
			slide.style.height = newSliderHeight + "px";
			slide.style.aspectRatio = "16/9";
			slide.style.width = "auto";
			slide.classList.remove("opacity-0");
		});
		
		// Set width of project container so all slides fit in, reduce it by one to make container a bit smaller, 40 = margin
		projectContainer.style.width = (projectSlides[0].offsetWidth * slidesCount - 1) + (slidesCount * 40) + "px";

		const projectCard = section.querySelector(".project-card");
		const allProjectsSlide = section.querySelector(".project-slide-all");

		if (!projectCard || !allProjectsSlide) return;

		allProjectsSlide.style.height = projectCard.clientHeight + "px";

		let slidesInPercent = 100 / slidesCount;
		let endTranslateValue = -100 + slidesInPercent + "%"; // Calculate the end value of transition to stop at last slide

		const animation = anime({
			targets: projectContainer,
			translateX: ["0%", endTranslateValue],
			easing: "linear",
			autoplay: false,
		});

		controller = new ScrollMagic.Controller();
		const sceneDuration = slidesCount * 1000;

		const scene = new ScrollMagic.Scene({ triggerElement: section, triggerHook: "onLeave", duration: sceneDuration })
			.setPin(section)
			.addTo(controller)


		scene.on("progress", function (e) {
			let progress = e.progress;
			animation.seek((progress * 1000));
		});
	},

}
