// import { unlock, lock } from "tua-body-scroll-lock";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import anime from "animejs";
import lottie from "lottie-web";
import utils from "../utils";
import { supportsPassiveEvents } from "detect-it";

const md = utils.getMQValue("md");
let successLottieAnimation;

export default {
	init() {
		const footer = document.querySelector("footer");
		const timeline = anime.timeline();

		if (!footer) return;

		const contactPageFormsContainer = footer.querySelector(".contact-page-forms");
		const formOverlay = footer.querySelector(".form-overlay");
		const contactForms = footer.querySelectorAll(".fui-i form");
		const lottieAnimationDiv = footer.querySelector(".lottie-animation");
		const animationTarget = footer.querySelectorAll(".text-animation-line");
		const closeIcon = document.querySelector(".close-icon-div");
		const clickOutsideDiv = document.querySelector(".click-outside-div");

		if (!contactForms || !lottieAnimationDiv || !closeIcon || !clickOutsideDiv || !animationTarget) return;

		clickOutsideDiv.classList.remove("pointer-events-none");
		const animationUrl = lottieAnimationDiv.dataset.animationUrl;

		if (!animationUrl) return;

		const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty("--app-height-form", `${window.innerHeight}px`)
        }

		// remove lottie svg if it already exists
		if (successLottieAnimation) {
			let lottieSvg = lottieAnimationDiv.querySelector("svg");

			lottieSvg.remove();
		}

		successLottieAnimation = lottie.loadAnimation({
			container: lottieAnimationDiv,
			autoplay: false,
			path: animationUrl,
			loop: false,
		});

		if (!successLottieAnimation || !contactPageFormsContainer || !formOverlay) return;

		contactPageFormsContainer.classList.remove("hidden");

		// Adapt menu height if window size changes
        window.addEventListener("resize", () => {
            appHeight();
        }, supportsPassiveEvents ? { passive: true } : false);

		if (window.matchMedia("screen and (min-width: " + md + "px)").matches) {
			setHeightToModalContent();
		} else {
			appHeight();
		}

		timeline
			.add({
				targets: formOverlay,
				opacity: [0, 1],
				easing: "easeInOutQuint",
				duration: 300,
				complete: () => {
					formOverlay.classList.remove("pointer-events-none");
					disableBodyScroll(formOverlay); // Disable body scroll
				},
			})

		checkFormieSubmit();

		contactForms.forEach((form) => {
			closeIcon.addEventListener("click", () => {
				closeContactOverlay(form, formOverlay, successLottieAnimation, animationTarget);
			}, supportsPassiveEvents ? { passive: true } : false)

			clickOutsideDiv.addEventListener("click", () => {
				closeContactOverlay(form, formOverlay, successLottieAnimation, animationTarget);
			}, supportsPassiveEvents ? { passive: true } : false)
		})

		function setHeightToModalContent() {
			const modalContent = footer.querySelector(".modal-content");
			const formContainer = footer.querySelector(".contact-form-div");
			const form = footer.querySelector("form");

			if (!modalContent || !form || !formContainer) return;

			modalContent.style.height = form.clientHeight + 100 + "px";
			formContainer.style.height = form.clientHeight + 100 + "px";
		}

		/**
		 * Remove Magic Mouse Class of submit button
		 */
		function removeMagicMouseEffect() {
			const submitButton = document.querySelector(".fui-submit.magic-hover");
			const magicPointer = document.querySelector("#magicPointer");

			if (submitButton && magicPointer) {
				submitButton.classList.remove("magic-hover");
				magicPointer.classList.remove("pointer-overlay");
			}
		}

		/**
		 * By clicking outside or close button, close contact overlay
		 */
		function closeContactOverlay(form, formOverlay, lottieSuccessAnimation, animationTarget) {
			const timelineClose = anime.timeline();

			timelineClose
				.add({
					targets: formOverlay,
					opacity: [1, 0],
					easing: "easeInOutQuint",
					duration: 300,
					complete: () => {
						clickOutsideDiv.classList.add("pointer-events-none");
						formOverlay.classList.remove("pointer-events-none");
						contactPageFormsContainer.classList.add("hidden");
						enableBodyScroll(formOverlay); // Enable body scroll
					},
				})

			form.removeAttribute("style");
			lottieSuccessAnimation.stop();
			animationTarget.forEach((elem) => {
				elem.removeAttribute("style");
			})
		}

		/**
		 * Fade in success text
		 */
		function startSuccessAnimation() {
			const successAnimationContactForm = anime.timeline({
				delay: 1800, // set delay, so it starts after loading state
			});
			const successAnimationDiv = formOverlay.querySelector(".success-animation-div");

			if (!successAnimationDiv) return;

			successAnimationDiv.classList.add("z-20");

			const modal = formOverlay.querySelector(".modal");
			const homeButton = formOverlay.querySelector(".home-button-modal");

			if (!modal || !homeButton) return;

			if (window.matchMedia("screen and (min-width: " + md + "px)").matches) {
				successAnimationContactForm
					.add({
						targets: modal,
						height: "100vh",
						width: "100vw",
						easing: "easeOutQuint",
						duration: 600,
					}, 1200)
					.add({
						targets: animationTarget,
						translateY: -50,
						opacity: 1,
						delay: anime.stagger(400, { start: 1600 }), // start at 1600, so it starts after loading state
						easing: "easeOutQuint",
						duration: 400,
					}, 1600)
					.add({
						targets: homeButton,
						opacity: 1,
						easing: "easeOutQuint",
						duration: 100,
						complete: () => {
							homeButton.classList.remove("pointer-events-none");
						},
					}, "-=1500")
			} else {
				successAnimationContactForm
					.add({
						targets: animationTarget,
						translateY: -50,
						opacity: 1,
						delay: anime.stagger(400, { start: 1800 }), // start at 1800, so it starts after loading state
						easing: "easeOutQuint",
						duration: 400,
					}, 1800)
					.add({
						targets: homeButton,
						opacity: 1,
						easing: "easeOutQuint",
						duration: 100,
						complete: () => {
							homeButton.classList.remove("pointer-events-none");
						},
					}, "-=1500")
			}

		}

		/**
		 * Show Lottie Animation on validate
		 * Call success animation after submit
		 */
		function checkFormieSubmit() {
			const form = footer.querySelector("form");
			const successAnimationDiv = formOverlay.querySelector(".success-animation-div");

			if (!form || !successAnimationDiv) return;

			form.addEventListener("onFormieValidate", (e) => {
				if (!e.detail.invalid) {
					const loadingTimeline = anime.timeline();
					let playPromise;

					loadingTimeline
						.add({
							targets: closeIcon,
							opacity: [1, 0],
							duration: 100,
							delay: 400,
							easing: "easeInOutQuint",
							begin: () => {
								closeIcon.classList.add("pointer-events-none");
							},
							complete: () => {
								successAnimationDiv.classList.remove("opacity-0");
								playPromise = successLottieAnimation.play();
							},
						})


					setTimeout(() => {
						if (playPromise !== undefined) {
							playPromise.then(() => {
								successLottieAnimation.pause();
							})
								.catch(error => {
									console.log("e", error)
								});
						}
					}, 500);
				}
			}, supportsPassiveEvents ? { passive: true } : false)

			form.addEventListener("onAfterFormieSubmit", () => {
				startSuccessAnimation();
				removeMagicMouseEffect();
			}, supportsPassiveEvents ? { passive: true } : false)
		}
	},
}
