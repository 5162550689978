export default {
	init() {
		const header = document.querySelector("header");
		const body = document.querySelector("body");
		const firstSection = document.querySelector("section");

		if (!header || !firstSection || !body) {
			return;
		}

		if (firstSection) {
			const classlist = firstSection.classList;
			const headerClasslist = header.classList;
			const bodyClasslist = body.classList;

			if (!classlist || !headerClasslist || !bodyClasslist) {
				return;
			}

			// remove theming class of header if exists
			for (let i = 0; i < headerClasslist.length; i++) {
				if (headerClasslist[i].match(/^theming-/)) {
					header.classList.remove(headerClasslist[i]);
				}
			}

			bodyClasslist.forEach((bodyClass) => {
				if (bodyClass.match(/^body-theming-/)) {
					body.classList.remove(bodyClass);
				}
			})

			// add new theming class to header depening on first section theming
			for (let i = 0; i < classlist.length; i++) {
				if (classlist[i].match(/^theming-/)) {
					header.classList.add(classlist[i]);
					body.classList.add("body-" + classlist[i]);
				}
			}
		}
	},

	getHeaderTheming(firstSection) {
		const header = document.querySelector("header");
		let theming;

		if (!header || !firstSection) return;

		const classlist = firstSection.classList;


		// add new theming class to header depening on first section theming
		for (let i = 0; i < classlist.length; i++) {
			if (classlist[i].match(/^theming-/)) {
				theming = classlist[i];
			}
		}

		return theming;
	},
}