export default {
	init() {
		const footer = document.querySelector("footer");

		if (!footer) return;

		const projectPage = document.querySelector(".project-page");
		const projectDetailPage = document.querySelector(".project-details-page");
		const jobDetailPage = document.querySelector(".job-details-page");
		const contactPage = document.querySelector(".contact-page");
		const footerContainer = footer.querySelector(".container");

		if (!footerContainer) return;

		if (projectPage || projectDetailPage || jobDetailPage) {
			footer.classList.add("hidden");
		}
		else if (contactPage) {
			if (footer.classList.contains("hidden")) {
				footer.classList.remove("hidden");
			}
			footerContainer.style.display = "none";
		}
		else {
			if (footer.classList.contains("hidden")) {
				footer.classList.remove("hidden");
			}
			footerContainer.removeAttribute("style");
		}
	},
}