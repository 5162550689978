import { supportsPassiveEvents } from "detect-it";

export default {
    init() {
        const contentDivs = document.querySelectorAll(".text-section .content-div");
        const windowWidth = window.innerWidth;

        if (!contentDivs) return;

        if (contentDivs.length && windowWidth <= 640) {
            contentDivs.forEach(contentDiv => {
                const headlines = contentDiv.querySelectorAll(".collapsible");

                if (headlines) {
                    headlines.forEach((headline) => {
                        headline.classList.add("magic-hover");
                    })
                }

                contentDiv.addEventListener("click", function (event) {
                    const activeContentDiv = document.querySelector(".content-div.active");
                    const chevronToggle = contentDiv.querySelector(".chevron-up");

                    event.stopPropagation();

                    if (activeContentDiv && activeContentDiv != contentDiv) {
                        activeContentDiv.classList.remove("active");
                        activeContentDiv.querySelector(".content-text-section").style.maxHeight = null;
                        activeContentDiv.querySelector(".chevron-up").classList.add("collapsed");
                    }

                    const textDiv = contentDiv.querySelector(".content-text-section");

                    if (contentDiv.classList.contains("active")) {
                        contentDiv.classList.remove("active");
                        chevronToggle.classList.add("collapsed");
                        textDiv.style.maxHeight = null;
                    } else {
                        contentDiv.classList.add("active");
                        textDiv.style.maxHeight = textDiv.scrollHeight + "px";
                        chevronToggle.classList.remove("collapsed");
                    }
                }, supportsPassiveEvents ? { passive: true } : false)
            });
        }
    },
}
