export default {

    init() {
        const numberedCards = document.querySelectorAll(".numbered-card");

        if (numberedCards) {
            numberedCards.forEach(card => {
                const richText = card.querySelector(".rich-text");
                if (richText) {
                    const links = richText.querySelectorAll("a");
                    if (links) {
                        links.forEach(link => {
                            if (link) {
                                link.parentElement.classList.add("magic-hover");
                            }
                        })
                    }
                }
            });
        }
    },
}