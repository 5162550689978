import lottie from "lottie-web";
import { supportsPassiveEvents } from "detect-it";

export default {
	init(item) {
		const iconFigure = item.querySelector("figure");

		if (!iconFigure) {
			return;
		}

		const iconUrl = iconFigure.dataset.iconUrl;

		if (!iconUrl) {
			return;
		}

		let iconAnimation = lottie.loadAnimation({
			container: iconFigure,
			autoplay: false,
			path: iconUrl,
			loop: false,
		});

		if (!iconAnimation) {
			return;
		}

		iconAnimation.setSpeed(1.8);

		item.addEventListener("mouseover", function () {
			iconAnimation.setDirection(1);
			let playPromise = iconAnimation.play();

			if (playPromise !== undefined) {
				playPromise.then(() => {
					iconAnimation.pause();
				})
					.catch(error => {
						console.log("e", error)
					});
			}
		}, supportsPassiveEvents ? { passive: true } : false)


		item.addEventListener("mouseleave", function () {
			iconAnimation.goToAndStop(0, true)
			// iconAnimation.setDirection(-1);
			// let playPromise = iconAnimation.play();

			// if (playPromise !== undefined) {
			// 	playPromise.then(() => {
			// 		iconAnimation.pause();
			// 	})
			// 		.catch(error => {
			// 			console.log("e", error)
			// 		});
			// }
		}, supportsPassiveEvents ? { passive: true } : false)

		item.addEventListener("click", function () {
			iconAnimation.stop();
		}, supportsPassiveEvents ? { passive: true } : false)
	},
}
