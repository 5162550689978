import anime from "animejs";
import utils from "../utils";
import { supportsPassiveEvents } from "detect-it";

const lg = utils.getMQValue("lg");

export default {

	init(section) {
		const button = section.querySelector("#refreshClientsBtn");
		const clientsContainer = section.querySelector(".clients");
		let clients = section.querySelectorAll(".client");

		if (!button || !clients || !clientsContainer) return;

		// turn client node list into array
		const clientsArray = Array.prototype.slice.call(clients);

		// set limit depending on device size
		let limit;
		if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
			limit = 15;
		} else {
			limit = 8;
		}

		// remove origin clients to set new order and length
		clientsContainer.innerHTML = "";

		let timelineIncoming;
		let timelineOutcoming;

		setNewShuffleContent(clientsArray, limit, clientsContainer);

		button.addEventListener("click", () => {
			animateOutcomingShuffle(clientsArray, limit, clientsContainer);

			animateButton(button);
		}, supportsPassiveEvents ? { passive: true } : false)

		/**
		 * Get origin array and reorder items by shuffling it. Afterwards append new order to parent div.
		 * 
		 * @param {array} array of divs that will be reorderd  
		 * @param {int} limit set length of array
		 * @param {div} parentElement append to parent div to show in frontend
		 */
		function setNewShuffleContent(array, limit, parentElement) {
			let originArray = [...array];
			let shuffledArray = shuffleArray(array);

			parentElement.innerHTML = "";
			shuffledArray.length = limit;

			if (arrayEquals(originArray, shuffledArray)) {
				shuffleArray(originArray);
			}

			shuffledArray.forEach(elem => {
				parentElement.appendChild(elem)
			});

			animateIncomingShuffle(shuffledArray);
		}

		/**
		 * Shuffle array elements and return result
		 * 
		 * @param {array} array 
		 * @returns 
		 */
		function shuffleArray(array) {
			let shuffledArray = [...array];
			for (let i = shuffledArray.length - 1; i > 0; i--) {

				// Generate random number
				let j = Math.floor(Math.random() * (i + 1));

				let temp = shuffledArray[i];
				shuffledArray[i] = shuffledArray[j];
				shuffledArray[j] = temp;
			}

			return shuffledArray;
		}

		/**
		 * Animate incoming clients after button click
		 * 
		 * @param {array} array of clients
		 */
		function animateIncomingShuffle(array) {
			const texts = [];
			array.forEach(elem => {
				texts.push(elem.querySelector(".text-origin"))
			});

			timelineIncoming = anime.timeline();

			timelineIncoming
				.add({
					targets: texts,
					// keyframes: [
					// 	{ "-webkit-clip-path": "inset(100% 0 0 0)", clipPath: "inset(100% 0 0 0)" },
					// 	{ "-webkit-clip-path": "inset(0% 0 0 0)", clipPath: "inset(0% 0 0 0)" },
					// ],
					opacity: [0, 0.3],
					duration: 500,
					easing: "easeOutQuint",
					// delay: function (el, i) {
					// 	if (i == 0) {
					// 		return 0;
					// 	} else {
					// 		let random = anime.random(0, 300);
					// 		return random;
					// 	}
					// },
					delay: function (el, i) {
						if (i == 0) {
							return 0;
						} else {
							let random = anime.random(0, 400);
							return random;
						}
					},
				})
		}

		/**
		 * Animate outcoming clients after button click
		 * 
		 * @param {array} array of clients
		 */
		function animateOutcomingShuffle(array, limit, container) {
			const texts = [];
			array.forEach((client) => {
				texts.push(client.querySelector(".text-origin"));
			})

			timelineOutcoming = anime.timeline();

			timelineOutcoming
				.add({
					targets: texts,
					// keyframes: [
					// 	{ "-webkit-clip-path": "inset(0% 0 0 0)", clipPath: "inset(0% 0 0 0)" },
					// 	{ "-webkit-clip-path": "inset(100% 0 0 0)", clipPath: "inset(100% 0 0 0)" },
					// ],
					opacity: [0.3, 0],
					easing: "easeOutQuint",
					duration: 500,
					// delay: function (el, i) {
					// 	if (i == 0) {
					// 		return 0;
					// 	} else {
					// 		let random = anime.random(0, 300);
					// 		return random;
					// 	}
					// },
					delay: function (el, i) {
						if (i == 0) {
							return 0;
						} else {
							let random = anime.random(0, 400);
							return random;
						}
					},
					complete: () => {
						setNewShuffleContent(array, limit, container)
					},
				})
		}

		/**
		 * Rotate button while shuffling animation is playing
		 * 
		 * @param {button} button 
		 */
		function animateButton(button) {
			button.classList.add("pointer-events-none");

			const incomingDuration = timelineIncoming.duration;
			const outcomingDuration = timelineOutcoming.duration;

			if (!incomingDuration || !outcomingDuration) return;

			const duration = incomingDuration + outcomingDuration + 200;

			const timeline = anime.timeline({
				duration: duration,
				easing: "easeInOutQuint",
				delay: 100,
				endDelay: 100,
			});

			timeline
				.add({
					targets: button,
					rotate: [0, 360],
					complete: () => {
						button.classList.remove("pointer-events-none");
					},
				})

			timeline.finished.then(function () {
				button.removeAttribute("style");
			});
		}

		/**
		 * Checks if two arrays have the same length and if their elements have the same position
		 * 
		 * @param {array1} a 
		 * @param {array2} b 
		 * @returns 
		 */
		function arrayEquals(a, b) {
			return Array.isArray(a) &&
				Array.isArray(b) &&
				a.length === b.length &&
				a.every((val, index) => val === b[index]);
		}
	},
}



