import React from "react";
import { observer } from "mobx-react-lite";
import arrowIcon from "../images/ic_arrow_white.svg";
import contactModal from "../js/components/contact-modal";
import { contactStore } from "./ContactStore";

export type ISubmitButtonProps = {
	buttonLabel: string;
}

const SubmitButton = observer((props: ISubmitButtonProps) => {
	function handleSubmit() {
		const formOverlay = document.querySelector(".form-overlay");
		const userText: String[] = [];

		contactStore.contactForm?.answers.forEach(answer => {
			if (answer.isSelected) {
				const startQuestion = contactStore.contactForm?.question;
				const startAnswer = answer.answer;

				const stepOne = String(startQuestion) + "\n" + String(startAnswer) + "\n";

				let stepTwo = "";
				let stepThree = "";

				// we use this for saving the single choice selection in a formie hidden field
				const formFieldName = answer.inputs[0].formFieldName

				answer.inputs[0].answers.forEach((subAnswer) => {
					if (subAnswer.type == "singleChoice" && subAnswer.isSelected) {
						const subAnswerQuestion = answer.inputs[0].question;
						const subAnswerVal = subAnswer.value;

						stepTwo = String(subAnswerQuestion) + "\n" + String(subAnswerVal) + "\n";

						if (answer.inputs[0].nextStep.nextStepQuestion) {
							contactStore.saveTextValue(answer.inputs[0].nextStep.uid)

							const nextStepQuestion = answer.inputs[0].nextStep.nextStepQuestion;
							const nextStepAnswerVal = answer.inputs[0].nextStep.nextStepAnswer;

							stepThree = String(nextStepQuestion) + ":\n" + String(nextStepAnswerVal) + "\n";
						}

						// find the associated form field, its required that formFieldName match the desired formie input field class
						if (formFieldName) {
							const hiddenFormField = formOverlay?.querySelector(`.${formFieldName} input`) as HTMLInputElement | null
							if (hiddenFormField) {
								hiddenFormField.value = subAnswer.handle ?? ""
							}
						}


					} else if (subAnswer.type == "textInput") {
						contactStore.saveTextValue(answer.inputs[0].uid)

						const subAnswerQuestion = answer.inputs[0].question;
						const subAnswerVal = subAnswer.value;

						stepTwo = String(subAnswerQuestion) + ":\n" + String(subAnswerVal) + "\n";
					}
				})

				userText.push(stepOne, stepTwo, stepThree)

			}

		});

		if (formOverlay) {
			contactModal.init();

			const hiddenField = formOverlay.querySelector(".react-input-field textarea") as HTMLTextAreaElement;

			if (hiddenField) {
				hiddenField.value = userText.join('\n');
			}
		}
	}

	let showBtn;

	contactStore.contactForm?.answers.forEach((answer) => {
		if (answer.isSelected) {
			showBtn = answer.inputs[0].showSubmitButton;
		}
	})

	return (
		<>
			<button
				onClick={handleSubmit}
				className={(showBtn ? "opacity-100" : "pointer-events-none opacity-0") + " magic-hover submit-button uppercase font-esrebond-medium text-button-jobs flex items-center"}
			>
				<span>{props.buttonLabel}</span>
				<img className="ml-6" src={arrowIcon} alt="arrow"></img>
			</button>
		</>
	)
});

export default SubmitButton;