import { supportsPassiveEvents } from "detect-it";
import longButtonClick from "../animations/long-button-click";
import ScrollMagic from "scrollmagic";
import anime from "animejs";
import utils from "../utils";

let controller;
let scene;
let timeline;

export default {
	init(detailPage) {
		let url = window.location;

		if (controller) {
			controller = controller.destroy(true);
		}

		if (scene) {
			scene = scene.destroy(true);
		}

		if (timeline) {
			timeline.remove();
		}

		if (!detailPage || !url) return;

		const projectCategories = detailPage.querySelector(".project-categories");

		// set height to project categories to enable scrolling
		if(projectCategories) {
			const windowHeight = window.innerHeight;
			const header = document.querySelector("header");

			if(!header) return;

			const headerHeight = header.offsetHeight;

			if(!headerHeight) return;

			// window height - header height - project details padding (6rem) - 30px space
			let fixHeight = windowHeight - headerHeight - 96 - 30;

			projectCategories.style.height = fixHeight + "px";
		}


		let params = new URLSearchParams(url.search);
		const projectCategoryContainers = detailPage.querySelectorAll(".project-category-container");
		const currentPage = detailPage.querySelector(".project-details-sections");

		if (!projectCategoryContainers || !currentPage) return;

		const currentId = currentPage.dataset.projectId;

		setNextEntry(currentId, params.get("category"));

		// Open Accordion on Page Load
		// open first element if no category is set
		if (!params.get("category")) {
			toggleAccordion(projectCategoryContainers[0]);
		} else {
			projectCategoryContainers.forEach((categoryContainer) => {
				const containerCategoryId = categoryContainer.dataset.categoryId;

				if (containerCategoryId && params.get("category") === containerCategoryId) {
					toggleAccordion(categoryContainer);
				}
			})
		}

		// Set animation class after page is loaded
		setTimeout(() => {
			const clientLinks = detailPage.querySelectorAll(".client-link");
			const clients = detailPage.querySelectorAll(".project-clients");
			const headlines = detailPage.querySelectorAll(".project-category-headline");

			if (!clientLinks || !headlines || !clients) return;

			clientLinks.forEach((link) => {
				link.classList.add("animate-client-link");
			})

			clients.forEach((client) => {
				client.classList.add("animate-clients");
			})

			headlines.forEach((headline) => {
				headline.classList.add("animate-headline");
			})
		}, 300);


		// Open Accordion on Click
		projectCategoryContainers.forEach(categoryContainer => {
			categoryContainer.addEventListener("click", function () {
				setNextEntry(currentId, params.get("category"));
				toggleAccordion(categoryContainer);
			}, supportsPassiveEvents ? { passive: true } : false)

		});

		/**
		 * Open active menu link and close other links
		 * set correct links depending on selected category
		 */
		function toggleAccordion(categoryContainer) {
			params.delete("category"); // delete category param 
			const activeCategoryContainer = document.querySelector(".project-category-container.active");
			const categoryContainerId = categoryContainer.dataset.categoryId;
			const allClientLinks = document.querySelectorAll(".client-link");

			if (!categoryContainerId || !allClientLinks) return;

			if (activeCategoryContainer && activeCategoryContainer != categoryContainer) {
				activeCategoryContainer.classList.remove("active");
				activeCategoryContainer.querySelector(".project-clients").style.maxHeight = null;
				activeCategoryContainer.querySelector(".project-category-headline").classList.remove("active");
				utils.setLinkTabIndex(allClientLinks, -1);
			}

			const clients = categoryContainer.querySelector(".project-clients");
			const headline = categoryContainer.querySelector(".project-category-headline");

			if (!clients) return;

			const clientLinks = clients.querySelectorAll(".client-link");

			if (!clientLinks) return;

			if (categoryContainer.classList.contains("active")) {
				categoryContainer.classList.remove("active");
				headline.classList.remove("active");
				// clients.style.maxHeight = null;
				utils.setLinkTabIndex(allClientLinks, -1);
			} else {
				categoryContainer.classList.add("active");
				headline.classList.add("active");
				clients.style.maxHeight = clients.scrollHeight + "px";
				utils.setLinkTabIndex(clientLinks, 0);
			}

			params.append("category", categoryContainerId); // set category as url param
			history.replaceState(null, null, "?" + params.toString()); // push new param

			// add param to client links
			if (clientLinks) {
				clientLinks.forEach((link) => {
					let href = link.getAttribute("href");
					let currentHref = href.split("?");
					link.setAttribute("href", currentHref[0] + "?category=" + categoryContainerId)

					if (link.href === url.href) {
						link.classList.add("active")
					}
				})
			}
		}

		/**
		 * set correct next entry depending on category
		 */
		function setNextEntry(currentPageId, currentCategory) {
			controller = new ScrollMagic.Controller();
			const nextEntryContainer = detailPage.querySelector(".next-entry-container");

			if (!nextEntryContainer) return;

			const nextEntries = nextEntryContainer.querySelectorAll(".teaser-next-project");
			const currentEntries = [];
			let setNextElem = false;

			nextEntryContainer.innerHTML = "";

			nextEntries.forEach((entry, index) => {
				// if entry contains same category as current category save in array
				const categoriesString = entry.dataset.categories;

				if (!categoriesString) return;

				const categories = categoriesString.split(",");

				// save elements with current category in array
				if (currentCategory === "0" || currentCategory === null || currentCategory === "all") {
					currentCategory = "all";
					currentEntries.push(entry);
				} else {
					categories.forEach((category) => {
						if (category == currentCategory) {
							currentEntries.push(entry);
						}
					})
				}

				if (setNextElem === false) {
					let currentIndex;

					currentEntries.forEach((entry, i) => {
						const nextEntryId = entry.dataset.projectId;

						if (currentPageId === nextEntryId) {
							currentIndex = i;
						}

						if (i > currentIndex) {
							nextEntryContainer.append(entry);
							setNextElem = true;
						}
					})
				}

				if (setNextElem === false && index === nextEntries.length - 1) { // if its the last element, show first element
					nextEntryContainer.append(currentEntries[0]);
				}

				// set correct link param
				setTimeout(() => {
					const nextProject = detailPage.querySelector(".teaser-next-project");
	
					if (!nextProject) return;
	
					const nextProjectButton = nextProject.querySelector("a.button-next-project");
	
					if (nextProjectButton) {
						let href = nextProjectButton.getAttribute("href");
						let currentHref = href.split("?");
						nextProjectButton.setAttribute("href", currentHref[0] + "?category=" + currentCategory)
	
						longButtonClick.init(detailPage);
					}
				}, 10);
			})

			// animate next project if user reaches bottom
			const triggerBottom = detailPage.querySelector(".trigger-bottom");
			timeline = anime.timeline();
			const nextProject = detailPage.querySelector(".teaser-next-project");

			if (!triggerBottom) return;

			scene = new ScrollMagic.Scene({ triggerElement: triggerBottom, triggerHook: "onEnter", duration: 0 })
				.addTo(controller)

			if (!nextProject) return;

			const projectHeight = nextProject.offsetHeight - 20;
			const pageBottom = document.body.scrollHeight;

			if (!projectHeight) return;

			let animationIsPlaying = false;
			let animationPlayed = false;

			scene.on("enter", function (e) {
				let scrollDirection = e.target.controller().info("scrollDirection");

				if (scrollDirection == "FORWARD") {
					if (!animationPlayed) {
						timeline
							.add({
								begin: () => {
									if (!animationIsPlaying) {
										animationIsPlaying = true;
									}
								},
								update: () => {
									if (animationIsPlaying) {
										window.scrollTo(0, pageBottom);
									}
								},
								targets: nextEntryContainer,
								height: ["0px", projectHeight + "px"],
								easing: "easeOutQuint",
								duration: 1100,
								delay: 700,
								complete: () => {
									animationPlayed = true;
									if (animationIsPlaying) {
										animationIsPlaying = false;
									}
								},
							})
					}
				}
			})
		}

	},
}
