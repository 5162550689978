export default {

	init(footer) {
		if(!footer) return;

		const links = footer.querySelectorAll(".redactor.footer a");

		if(!links) return;

		links.forEach(link => {
			link.classList.add("magic-hover");
		});
	},
}
