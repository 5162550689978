import { supportsPassiveEvents } from "detect-it";

export default {
	init() {
		const links = document.querySelectorAll("link");

		if (!links) return;

		const lightmodeLinks = [];
		const darkmodeLinks = [];

		links.forEach((link) => {
			if (link.id.match(/^faviconTag-/) || link.id.match(/^faviconTagTouch-/)) {
				lightmodeLinks.push(link);
				link.parentNode.removeChild(link);
			}
			if (link.id.match(/^faviconTagDarkmode-/) || link.id.match(/^faviconTagTouchDarkmode-/)) {
				darkmodeLinks.push(link);
				link.parentNode.removeChild(link);
			}
		})

		const isDark = window.matchMedia("(prefers-color-scheme: dark)");
		const head = document.querySelector("head");

		if (!head) return;

		const changeFavicon = () => {
			if (isDark.matches) {
				if (darkmodeLinks.length) {
					darkmodeLinks.forEach((link) => {
						head.appendChild(link);
					})
				}
			} else {
				if (lightmodeLinks.length) {
					lightmodeLinks.forEach((link) => {
						head.appendChild(link);
					})
				}
			}
		};

		changeFavicon();

		if (window.matchMedia("(prefers-color-scheme: dark)").addEventListener) {
			window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
				changeFavicon();
			}, supportsPassiveEvents ? { passive: true } : false);
		}
	},
}
