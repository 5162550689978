import anime from "animejs";
import utils from "../utils";
import { supportsPassiveEvents } from "detect-it";

export default {

	init(detailPage) {
		const lg = utils.getMQValue("lg");
		const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
		const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

		if (!detailPage) return;

		const nextProjectBtn = detailPage.querySelector(".button-next-project");
		const nextProject = detailPage.querySelector(".teaser-container");
		const sectionContainer = detailPage.querySelector(".project-details-sections");

		if (!nextProjectBtn || !nextProject || !sectionContainer) return;

		let holdActive = false;
		let firstHold = true;
		const btnBg = nextProjectBtn.querySelector(".btn-color");
		const textMask = nextProjectBtn.querySelector(".text-mask");
		const timeline = anime.timeline({
			autoplay: false,
			easing: "easeOutCubic",
			duration: 975,
			delay: 0,
		});
		const btnSpan = nextProjectBtn.querySelector("span.origin-text");
		const buttonContainerInner = document.querySelector(".button-container-inner");

		const teaserTimeline = anime.timeline({
			autoplay: false,
			easing: "easeOutQuint",
			duration: 975,
			delay: 0,
		});

		const sectionTimeline = anime.timeline({
			autoplay: false,
			easing: "easeOutQuint",
			duration: 975,
			delay: 0,
		});

		if (!btnBg || !textMask || !timeline || !teaserTimeline || !sectionTimeline) return;

		timeline
			.add({
				targets: [btnBg, textMask],
				height: ["32%", "100%"],
			})

		// only animate next project on large devices
		if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
			teaserTimeline
				.add({
					targets: nextProject,
					translateY: ["8rem", "3rem"],
				})
		}

		sectionTimeline
			.add({
				targets: sectionContainer,
				opacity: [1, 0],
			})

		
		if (!iOS || !isSafari) {
			// hold mouse click
			nextProjectBtn.addEventListener("mousedown", (event) => {
				event.preventDefault();
				event.stopPropagation();
	
				holdActive = true;
	
				if (firstHold) {
					timeline.play();
					teaserTimeline.play();
					sectionTimeline.play();
					firstHold = false;
				} else {
					timeline.direction = "normal";
					teaserTimeline.direction = "normal";
					sectionTimeline.direction = "normal";
					timeline.play();
					teaserTimeline.play();
					sectionTimeline.play();
				}
	
				timeline.finished.then(() => {
					nextProjectBtn.click();
				});
			});
			
			// hold mouse touch on mobile devices
			nextProjectBtn.addEventListener("touchstart", (event) => {
				// event.preventDefault();
				event.stopPropagation();
	
				btnBg.classList.remove("opacity-0");
				textMask.classList.remove("opacity-0");
	
				holdActive = true;
	
				if (firstHold) {
					timeline.play();
					sectionTimeline.play();
					if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
						teaserTimeline.play();
					}
					firstHold = false;
				} else {
					timeline.direction = "normal";
					sectionTimeline.direction = "normal";
					timeline.play();
					sectionTimeline.play();
					if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
						teaserTimeline.direction = "normal";
						teaserTimeline.play();
					}
				}
	
				timeline.finished.then(() => {
					nextProjectBtn.click();
				});
			}, supportsPassiveEvents ? { passive: true } : false);
			
			// stop hold mouse click
			nextProjectBtn.addEventListener("mouseup", (event) => {
	
				event.preventDefault();
				event.stopPropagation();
	
				if (holdActive) {
					holdActive = false;
					timeline.reverse();
					teaserTimeline.reverse();
					sectionTimeline.reverse();
				}
			});

			// stop mouse touch on mobile devices
			nextProjectBtn.addEventListener("touchend", (event) => {
	
				event.preventDefault();
				event.stopPropagation();
	
				btnBg.classList.add("opacity-0");
				textMask.classList.add("opacity-0");
	
				if (holdActive) {
					holdActive = false;
					timeline.reverse();
					sectionTimeline.reverse();
					if (window.matchMedia("screen and (min-width: " + lg + "px)").matches) {
						teaserTimeline.reverse();
					}
				}
			});

			// stop click event if button click is holding
			nextProjectBtn.addEventListener("click", (event) => {
				if (!holdActive) {
					event.preventDefault();
					event.stopPropagation();
				}
			})
		} else {
			nextProjectBtn.classList.add("ios-hover");

			if (!btnSpan && !buttonContainerInner) return;

			btnSpan.classList.add("text-0");
			buttonContainerInner.classList.add("h-full");
		}

		// For accessibility show next entry on enter button press
		nextProjectBtn.addEventListener("keyup", function (event) {
			// Number 13 is the "Enter" key on the keyboard
			if (event.keyCode === 13) {
				holdActive = true;
				nextProjectBtn.click();
			}
		});

		// disable pop up menus by long press - not on ios safari
		if (!isSafari && !iOS) {
			nextProjectBtn.addEventListener("contextmenu", e => {
				e.preventDefault();
			});
		}
	},
}
