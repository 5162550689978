import { action, makeObservable, observable } from "mobx";
import { IContactFormSelection } from "./contactInputTypes";

class ContactStore {
	contactForm?: IContactFormSelection;

	constructor() {
		makeObservable(this, {
			contactForm: observable,
			selectAnswer: action,
			selectSubAnswer: action
		})
	}

	// Select answer of first single choice
	selectAnswer(answerId: string) {
		const newContactForm = this.contactForm

		newContactForm?.answers.forEach((answer) => {
			if (answer.uid === answerId) {
				answer.isSelected = true;
				newContactForm.activeId = answer.inputs[0].uid;
				newContactForm.isActive = false;
				answer.inputs[0].isActive = true;
				if (answer.inputs[0].type === "textInput") {
					answer.inputs[0].showSubmitButton = true;
				}
			} else {
				answer.isSelected = false;
			}
		})
		this.contactForm = newContactForm
	}

	// Select Answer of second single choice if existing
	selectSubAnswer(answerId: string) {
		const newContactForm = this.contactForm
		newContactForm?.answers.forEach((answers) => {
			answers.inputs.forEach((input) => {
				input.answers.forEach((subAnswer) => {
					if (subAnswer.uid === answerId) {
						subAnswer.isSelected = true;
						input.nextStep.showNextStep = true;
						newContactForm.activeId = input.nextStep.uid;
						input.showSubmitButton = true;
						input.isActive = false;
					} else {
						subAnswer.isSelected = false;
					}
				})
			})
		})
		this.contactForm = newContactForm
	}

	// Save value of text area
	saveTextValue(inputId: string) {
		const textArea = document.getElementById("input-" + inputId) as HTMLTextAreaElement;
		let textAreaValue: string | boolean | null = null;

		if (textArea) {
			textAreaValue = textArea.value;
		}

		const newContactForm = this.contactForm
		newContactForm?.answers.forEach((answers) => {
			answers.inputs.forEach((input) => {
				if (input.uid === inputId) {
					input.answers.forEach((subAnswer) => {
						subAnswer.value = textAreaValue;
					})
				} else if (input.nextStep.uid === inputId) {
					input.nextStep.nextStepAnswer = textAreaValue;
				}
			})
		})
		this.contactForm = newContactForm
	}
}
const contactStore = new ContactStore();
export { contactStore }