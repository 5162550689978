import { supportsPassiveEvents } from "detect-it";

export default {

	init(pageContainer) {
		const header = document.querySelector("header");
		const currentUrl = window.location.href.split("?")[0]; // take url without params

		if (!pageContainer || !header || !currentUrl) return;

		const languageSwitchers = header.querySelectorAll(".lang-switcher");
		const deUrl = pageContainer.dataset.deUrl;
		const enUrl = pageContainer.dataset.enUrl;

		if (!deUrl || !enUrl) {
			languageSwitchers.forEach((languageSwitcher) => {
				languageSwitcher.classList.add("hidden");
			})
		}

		if (!deUrl || !enUrl || !languageSwitchers) return;

		languageSwitchers.forEach((languageSwitcher) => {
			const deLink = languageSwitcher.querySelector(".de a");
			const enLink = languageSwitcher.querySelector(".en a");

			// set correct links to language switcher
			deLink.href = deUrl;
			enLink.href = enUrl;

			// not working in localhost, because of missing https
			if (currentUrl == deUrl) {
				setActiveElement(deLink, enLink);
			}

			// not working in localhost, because of missing https
			if (currentUrl == enUrl) {
				setActiveElement(enLink, deLink);
			}

			deLink.addEventListener("click", reload, supportsPassiveEvents ? { passive: true } : false);
			enLink.addEventListener("click", reload, supportsPassiveEvents ? { passive: true } : false);
		})


		// reload page after languagse changes
		function reload() {
			window.setTimeout(function () {
				window.location.reload();
			}, 10);
		}

		// set active and not active elements
		function setActiveElement(activeElem, otherElem) {
			activeElem.parentElement.classList.add("is-active");
			activeElem.parentElement.classList.add("pointer-events-none");
			otherElem.parentElement.classList.add("not-active");
			otherElem.parentElement.classList.add("magic-hover");
			activeElem.parentElement.classList.remove("not-active");
			activeElem.parentElement.classList.remove("magic-hover");
			otherElem.parentElement.classList.remove("is-active");
			otherElem.parentElement.classList.remove("pointer-events-none");
		}
	},
}