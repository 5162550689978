import * as Klaro from "klaro/dist/klaro-no-css";

export default {

	init() {

		const config = {
			elementID: "cookie-banner",
			storageMethod: "cookie",
			storageName: "klaro-cookie",
			mustConsent: false, // Cookie banner is a Modal
			acceptAll: true,
			hideDeclineAll: false,
			translations: {
				de: {
					privacyPolicyUrl: "http://dotsandlines.io/datenschutz",
					consentNotice: {
						description: "Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}",
						learnMore: "Einstellungen",
					},
					consentModal: {
						title: "Cookie Einstellungen",
						description: "Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Datenschutzerklärung (Name)",
						},
					},
					ok: "Alle akzeptieren",
					decline: "Funktionale Cookies",
					acceptSelected: "Speichern",
					save: "Speichern",
					close: "Schließen",
					acceptAll: "Alle akzeptieren",
					purposes: {
						essential: "Essentiell",
						functional: "Funktionale Cookies",
						analytics: "Statistiken",
						marketing: "Marketing",
					},
				},
				en: {
					privacyPolicyUrl: "http://dotsandlines.io/en/data-protection-policy",
					consentNotice: {
						description: "We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}",
						learnMore: "Settings",
					},
					consentModal: {
						title: "Cookie settings",
						description: "We use cookies to offer you the best possible functionality. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Privacy Policy (Name)",
						},
					},
					ok: "Accept all",
					decline: "Functional cookies",
					acceptSelected: "Save selection",
					save: "Save",
					close: "Close",
					acceptAll: "Accept all",
					purposes: {
						essential: "essential",
						functional: "Functional cookies",
						analytics: "Statistic",
						marketing: "Marketing",
					},
				},
			},
			services: [
				{
					name: "craft-cms",
					title: "Craft CMS",
					default: true,
					purposes: ["essential"],
					cookies: [
						["CraftSessionId"],
						["*_identity"],
						["*_username"],
						["CRAFT_CSRF_TOKEN"],
					],
					required: true,
				},
				{
					name: "klaro",
					title: "Klaro!",
					default: true,
					purposes: ["essential"],
					required: true,
				},
				{
                    name: "gtm",
                    title: "Google Tag Manager",
					default: true,
                    purposes: ["essential"],
                    cookies: [
                        [/^_dc_gtm.*$/i, "/", ".dotsandlines.io"],
                        [/^_gtm.*$/i, "/", ".dotsandlines.io"],
						["HSID", "/", ".google.at"],
                    ],
                    required: true,
                },
				{
					name: "google-analytics",
					title: "Google Analytics",
					purposes: ["analytics"],
					cookies: [
						[/^_ga.*$/i, "/", ".dotsandlines.io"],
						[/^_gat_*$/i, "/", ".dotsandlines.io"],
                        ["_gid", "/", ".dotsandlines.io"],
						["_ga", "/", ".dotsandlines.io"],
					],
					required: false,
				},
				{
					name: "meta-pixel",
					title: "Meta Pixel",
					purposes: ["marketing"],
					cookies: [
						["_fbp", "/", ".dotsandlines.io"],
					],
					required: false,
				},
				{
					name: "google-double-click",
					title: "Google Double Click",
					purposes: ["marketing"],
					cookies: [
						["IDE", "/", ".doubleclick.net"],
					],
					required: false,
				},
				{
					name: "google-ads",
					title: "Google Ads",
					purposes: ["marketing"],
					cookies: [
						["APISID", "/", ".google.at"],
						["SID", "/", ".google.at"],
						["SAPISID", "/", ".google.at"],
						["SSID", "/", ".google.at"],
						["__Secure-1PSID", "/", ".google.at"],
						["__Secure-1PAPISID", "/", ".google.at"],
						["__Secure-1PSIDCC", "/", ".google.com"],
						["1P_JAR", "/", ".google.com"],
						["NID", "/", ".google.com"],
						["__Secure-3PSID", "/", ".google.com"],
						["__Secure-3PAPISID", "/", ".google.com"],
						["AEC", "/", ".google.com"],
						["__Secure-1PAPISID", "/", ".google.com"],
						["SAPISID", "/", ".google.com"],
						["__Secure-1PSID", "/", ".google.com"],
						["SID", "/", ".google.com"],
						["SOCS", "/", ".google.com"],
						["SSID", "/", ".google.com"],
						["APISID", "/", ".google.com"],
						["CONSENT", "/", ".google.com"],
						["HSID", "/", ".google.com"],
						["__Secure-3PSIDCC", "/", ".google.com"],
						["SIDCC", "/", ".google.com"],
						["OTZ", "/", "www.google.com"],
						["DV", "/", "www.google.com"],
						["_gcl_au", "/", ".dotsandlines.io"],
					],
					required: false,
				},
				{
					name: "linkedin",
					title: "LinkedIn",
					purposes: ["marketing"],
					cookies: [
						["bscookie", "/", ".linkedin.com"],
						["li_gc", "/", ".linkedin.com"],
						["lidc", "/", ".linkedin.com"],
						["UserMatchHistory", "/", ".linkedin.com"],
						["AnalyticsSyncHistory", "/", ".linkedin.com"],
						["bcookie", "/", ".linkedin.com"],
						["lang", "/", ".linkedin.com"],
						["ln_or", "/", ".linkedin.com"],
						["ln_or", "/", "www.dotsandlines.io"],
					],
					required: false,
				},
			],
			callback: function (consent, service) {
				console.log(
					"User consent for service " + service.name + ": consent=" + consent
				);
			},
		};

		// we assign the Klaro module to the window, so that we can access it in JS
		window.klaro = Klaro;
		window.klaroConfig = config;
		// we set up Klaro with the config
		Klaro.setup(config);

	},
}
