export default {
	init(section) {
		const links = section.querySelectorAll("a");

		if (!links) return;

		links.forEach(link => {
			link.classList.add("magic-hover");
		});
	},
}
