export default {

	init() {
		const footer = document.querySelector("footer");

		if (!footer) return;

		refreshCSRFToken();
		addMagicMouseEffect();
		setTextClass();

		/**
		 * Replace CSRF Token
		 * Necessary if blitz plugin is activated
		 */
		function refreshCSRFToken() {
			let formContainers = footer.querySelectorAll(".form");

			formContainers.forEach(formContainer => {
				let formId = formContainer.dataset.formId;

				if (formId) {
					let form = document.querySelector("#" + formId);
					
					if (form) {
						let formHandle = form.querySelector("input[name=\"handle\"]");
						//Find the CSRF token hidden input, so we can replace it
						let csrfInput = form.querySelector("input[name=\"CRAFT_CSRF_TOKEN\"]");

						if (csrfInput || formHandle) {
							// Fetch the new token for the form and replace the CSRF input with our new one
							fetch("/actions/formie/forms/refresh-tokens?form=" + formHandle.value)
								.then(result => { return result.json(); })
								.then(result => { csrfInput.outerHTML = result.csrf.input; });
						}
					}
				}
			})
		}

		/**
		 * Add Magic Mouse Class to submit button
		 */
		function addMagicMouseEffect() {
			const submitButton = document.querySelector(".fui-submit");

			if (!submitButton) return;

			submitButton.classList.add("magic-hover");
		}

		/**
		 * set text animation class to redactor paragraphs
		 */
		function setTextClass() {
			const modalSuccessTextContainer = document.querySelector(".modal-success-text");

			if (!modalSuccessTextContainer) return;

			const paragraphs = modalSuccessTextContainer.querySelectorAll("p");

			if (!paragraphs) return;

			paragraphs.forEach((p) => {
				p.classList.add("text-animation-line");
			})
		}
	},
}
