import { supportsPassiveEvents } from "detect-it";

export default {

	init() {
		const departmentSections = document.querySelectorAll(".department-section");

		if (departmentSections.length) {
			departmentSections.forEach(section => {
				const divContainer = section.querySelector(".container")
				const containerWidth = divContainer.offsetWidth;
				const members = document.querySelectorAll(".member");

				if (members) {
					members.forEach(member => {
						const memberImage = member.querySelector(".member-image");
						const memberSpan = member.querySelector(".member-name");

						if (memberImage && memberSpan) {
							member.addEventListener("mouseover", () => {
								memberImage.classList.remove("hidden");

								const imageHeight = memberImage.offsetHeight;
								const imageWidth = memberImage.offsetWidth;
								const spanHeight = memberSpan.offsetHeight;
								const spanWidth = memberSpan.offsetWidth;

								//to get the position of the member div on the window
								const clientRect = member.getBoundingClientRect();
								const clientRectLeft = clientRect.left

								// to calculate the rest of width space and then check if the image has place
								const restXSpace = containerWidth - clientRectLeft;

								const shiftValue = spanWidth * 70 / 100;

								if (spanWidth > containerWidth * 60 / 100) {//to center the image horizontally if no place right or left
									memberImage.style.left = (containerWidth - imageWidth) / 2 + "px";

								} else if (restXSpace > imageWidth + shiftValue) {//if it has place it will be the image on the left side
									const left = clientRectLeft + shiftValue;
									memberImage.style.left = left + "px";
								} else {//if it has no place it will be in the right side
									const left = clientRect.right - imageWidth - shiftValue;
									memberImage.style.left = left + "px";
								}

								//to center the image vertically
								memberImage.style.marginTop = (- imageHeight / 2) + (spanHeight / 2) + "px";
							}, supportsPassiveEvents ? { passive: true } : false);

							member.addEventListener("mouseout", () => {
								memberImage.classList.add("hidden");
							}, supportsPassiveEvents ? { passive: true } : false);
						}
					});
				}
			});
		}
	},
}
